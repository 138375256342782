%card-default-style {
    background-color: $u-card-bg-color;
    border-radius: $border-radius-base;
    box-shadow: $u-card-shadow-base;
    border: $u-card-border;
}
%card-default-style-reset{
    box-shadow: none;
    border-radius: 0;
    border: none;
}
%card-padding-base {
    padding: $u-card-padding-vertical $u-card-padding-horizontal;
}
%card-default-style-sm {
    @media (min-width: $screen-sm-min) {
        background-color: $u-card-bg-color;
        border-radius: $border-radius-base;
        box-shadow: $u-card-shadow-base;
        border: $u-card-border;
    }
}
%card-padding-base-sm {
    @media (min-width: $screen-sm-min) {
        padding: $u-card-padding-vertical $u-card-padding-horizontal;
    }
}
%card-default-style-xs {
    @media (max-width: $screen-xs-max) {
        background-color: $u-card-bg-color;
        border-radius: $border-radius-base;
        box-shadow: $u-card-shadow-base;
        border: $u-card-border;
    }
}
%card-padding-base-xs {
    @media (max-width: $screen-xs-max) {
        padding: $u-card-padding-vertical $u-card-padding-horizontal;
    }
}