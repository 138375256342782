/*UC110 Mobile view*/
.UC110CartGrid {
    @media (max-width: $screen-xs-max) {
        display: none;
    }
}
.BlockTempOrderLinesContainer {
    display: none;
    @media (max-width: $screen-xs-max) {
        display: block;
        .ProductDescriptionContainer {
            position: relative;
            border-bottom: 1px solid $table-border-color;
            &:not(:first-child) {
                margin-top: 10px;
            }
        }
        .ProductShortInfo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .DetailsKeyContainer {
                display: none;
            }
            .QuantityContainer {
                padding-right: 10px;
                flex-grow: 0;
            }
            .ItemTitleContainer{
                flex-grow: 1;
            }
            .ItemTitleLink {
                color: $text-color;
            }
            .SumContainer {
                text-align: right;
                flex-grow: 0;
            }
            .DeleteButtonContainer {
                width: 30px;
                padding-left: 10px;
                flex-grow: 0;
            }
        }
        .ProdDetailsContainer {
            float: none;
            clear: both;
            .ProductDetailsRow {
                div {
                    display: table-cell;
                }
                .DetailsKeyContainer {
                    width: 80px;
                    font-weight: $label-font-weight;
                }
            }
        }
        .ProdDetails {
            height: 100px;
            padding-top: 15px;
            margin-top: 15px;
            border-top: 1px dashed $table-border-color;
            font-size: 13px;
            a {
                text-decoration: none;
            }
        }
        .ImageContainer {
            float: right;
            border: 1px solid $table-border-color;
        }
        ///TODO: do we need DeleteTempOrderLineItem?
        //.DeleteTempOrderLineItem {
        //    float: right;
        //    top: 30px;
        //}
        //Don't use FoxSlider anywhere! Keep inside BlockTempOrderLinesContainer namespace (UC110 Detailed cart and UC140 Checkout 2 controls)
        .FoxSliderContent {
            display: none;
        }
    }
    .FoxSliderButton {
        width: 50px;
        line-height: 18px;
        height: 18px;
        margin: 0 auto;
        border-radius: $border-radius-base $border-radius-base 0 0;
        font-size: 7px;
        text-align: center;
        background-color: $brand-primary;
        @include u-set-adaptive-color ($brand-primary, $light-text-color: $cl_White, $dark-text-color: $text-color, $threshold: 50%);
        &::before {
            @extend %fa-chevron-down;
        }
    }
}