.smart-search {
    width: $site-search__width;
    position: relative;

    .twitter-typeahead {
        width: 100%;
    }
    &__btn {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        width: $site-search__button-width;
        height: $site-search__button-height;
        border: 0;
        background: none;
        font-size: $site-search__icon-font-size;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        &::before{
            @extend %un-search-slim;
        }
        .fa{
            display: none;
        }
    }
    &__input {
        width: 100%;
        height: $site-search__button-height;
        padding: $site-search__input-padding;
        border-radius: $btn-border-radius-base;
        outline: none;
        font-size: $site-search__input-xs-font-size;
        caret-color: $brand-primary;
        overflow: hidden;
        text-overflow: ellipsis;
        @media(min-width: $screen-sm-min){
            font-size: $site-search__input-font-size;
        }
    }

    /* TODO: BEM it! */
    .tt-query {
        box-shadow: inset 0 1px 1px rgba($gray-base, .075);
    }
    .tt-hint {
        color: $cl_Mountain_Mist2;
    }
    .tt-menu {
        width: 100%;
        min-width: 300px;
        max-width: 422px;
        background-color: $cl_White;
        border-radius: $border-radius-base;
        border: 1px solid rgba($gray-base, .2);
        box-shadow: 0 5px 10px rgba($gray-base, .2);

        @media (min-width: $screen-sm-min) {
            min-width: 350px;
        }

    }

}