%fa {
    font-family: 'FontAwesome';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

%fa-lock {
    @extend %fa;
    content: "\f023"; //fa fa-lock
}

%fa-home {
    @extend %fa;
    content: "\f015"; //fa fa-home
}

%fa-angle-left {
    @extend %fa;
    content: "\f104"; //fa fa-angle-left
}

%fa-angle-right {
    @extend %fa;
    content: "\f105"; //fa fa-angle-right
}

%fa-chevron-left {
    @extend %fa;
    content: "\f053"; //fa fa-chevron-left
}

%fa-chevron-right {
    @extend %fa;
    content: "\f054"; //fa fa-chevron-right
}

%fa-chevron-up {
    @extend %fa;
    content: "\f077"; //fa-chevron-up
}

%fa-chevron-down {
    @extend %fa;
    content: "\f078"; //fa-chevron-down
}

%fa-document {
    @extend %fa;
    content: "\f15c"; //fa fa-document
}

%fa-calendar {
    @extend %fa;
    content: "\f073"; //fa fa-calendar
}

%fa-bolt {
    @extend %fa;
    content: "\f0e7"; //fa fa-bolt
}

%fa-circle {
    @extend %fa;
    content: "\f111"; //fa fa-circle
}

%fa-heart {
    @extend %fa;
    content: "\f004"; // fa-heart
}

%fa-heart-o {
    @extend %fa;
    content: "\f08a"; // fa-heart-o
}

%fa-rss {
    @extend %fa;
    content: "\f09e"; //fa fa-rss
}

%fa-instagram {
    @extend %fa;
    content: "\f16d"; //fa fa-instagram
}

%fa-times {
    @extend %fa;
    content: "\f00d"; //fa fa-times
}

%fa-play {
    @extend %fa;
    content: "\f04b"; //fa fa-play
}

%fa-envelope {
    @extend %fa;
    content: "\f0e0"; //fa fa-envelope
}

%fa-envelope-o {
    @extend %fa;
    content: "\f003"; //fa fa-envelope-o
}

%fa-envelope-open-o {
    @extend %fa;
    content: "\f2b7"; //fa fa-envelope-open-o
}

%fa-check {
    @extend %fa;
    content: "\f00c"; //fa fa-check
}

%fa-filter {
    @extend %fa;
    content: "\f0b0"; //fa fa-filter
}

%fa-plus {
    @extend %fa;
    content: "\f067"; //fa fa-plus
}

%fa-minus {
    @extend %fa;
    content: "\f068"; //fa fa-minus
}

%fa-phone {
    @extend %fa;
    content: "\f095"; //fa fa-phone
}

%fa-info-circle {
    @extend %fa;
    content: "\f05a"; //fa fa-info-circle
}

%fa-print {
    @extend %fa;
    content: "\f02f"; //fa fa-print
}

%fa-file-o {
    @extend %fa;
    content: "\f016"; //fa fa-file-o
}

%fa-star{
    @extend %fa;
    content: "\f005"; //fa fa-star
}

%fa-star-half-o{
    @extend %fa;
    content: "\f123"; //fa fa-star-half-o
}

%fa-star-o{
    @extend %fa;
    content: "\f006"; //fa fa-star-o
}

%fa-close{
    @extend %fa;
    content: "\f00d"; //fa fa-close
}

%fa-hand-rock-o{
    @extend %fa;
    content: "\f255"; //fa fa-hand-rock-o
}

%fa-cloud-upload {
    @extend %fa;
    content: "\f0ee"; //fa fa-cloud-upload
}

%fa-pencil{
    @extend %fa;
    content: "\f040"; //fa fa-pencil
}

%fa-thumbs-up{
    @extend %fa;
    content: "\f164"; //fa fa-thumbs-up
}

%fa-comment{
    @extend %fa;
    content: "\f075"; //fa fa-comment
}

%fa-trash{
    @extend %fa;
    content: "\f1f8"; //fa fa-trash
}

%fa-user{
    @extend %fa;
    content: "\f007"; //fa fa-user
}

%fa-file-text-o{
    @extend %fa;
    content: "\f0f6"; //fa fa-file-text-o
}

%fa-ellipsis-h{
    @extend %fa;
    content: "\f141"; //fa fa-ellipsis-h
}

%fa-truck{
    @extend %fa;
    content: "\f0d1"; //fa fa-truck
}

%fa-eye{
    @extend %fa;
    content: "\f06e"; //fa fa-eye
}

%fa-search{
    @extend %fa;
    content: "\f002"; //fa fa-search
}