/*UC174 List Child Pages*/
.UC174 {
    .ListContainer{
        display: flex;
        flex-wrap: wrap;
    }
    .Item{
        display: flex;
        @extend %card-default-style;
    }
    .ItemContainer{
        display: flex;
        flex-direction: row;
        align-items: center;
        @media (min-width: $screen-sm-min) {
            flex-direction: column;
            padding-bottom: 2rem;
        }
    }
    .ItemTitle{
        font-size: $font-size-large;
        font-weight: $label-font-weight;
    }
    .ItemTitleLink{
        @extend %u-list-title-d-to-l;
    }
    .ItemTitleContainer{
        padding: 0 1em;
        @media (min-width: $screen-sm-min) {
            text-align: center;
            margin-top: 2.4rem;
            margin-bottom: 2.4rem;
        }
    }
    .ImageContainer{
        width: 7rem;
        flex-shrink: 0;

        .Image {
            width: 100%;
        }

        @media (min-width: $screen-sm-min) {
            width: 100%;
        }
    }
    .ReadMoreContainer{
        margin-top: auto;
        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }
    //.TeaserContainer{ // limit height in case of usage
    //    @media (min-width: $screen-sm-min) {
    //        text-align: center;
    //    }
    //    @media (max-width: $screen-xs-max) {
    //        display: none;
    //    }
    //}
}