.uc212 {
    /*
    /// TODO: 
    1. Referrals - in or outside delivery (Lene + Per)
    2. Delivery header medata, register task
    3. Postal area - in JS
    3. "Swedbank Pay Logo" bad class name

    */
    $_this: &;

    $delivery-max-width:        568px;
    $uc212-gap:                 35px !default;
    $uc212-max-width:           480px !default;

    @mixin this_space-between($multiplier: 2){
        > * {
            &:not(:first-child){
                margin-top: $grid-size * $multiplier;
            }
        }
    }
    @mixin this_provider-col-gap(){
        @media (min-width: $screen-sm-min) {
            margin-right: 32px;
        }
    }

    @include this_space-between(6);

    h2{
        margin-bottom: $grid-size * 2;
        margin-top: 0;
    }

    .ui-widget{ //payment container
        border: none;
        padding: 0;
        background-color: transparent;
    }
    div.ui-tabs {
        .ui-tabs-nav {
            float: none !important;
            width: auto !important;
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            background-color: transparent;

            .Logo.Svea{
                max-width: 66px;
            }

            &::before,
            &::after {
                display: none;
            }

            li {
                display: flex;
                flex: 1;
                margin: 0 0 1px !important;
                border-radius: 0 !important;
                border: none;
                background-color: #EEEDEE;
                @media (min-width: $screen-sm-min) {
                    flex: 0 1;
                    margin: 0!important;
                }

                &:not(:first-child) {
                    margin-left: 1px !important;
                }
                a.ui-tabs-anchor {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    min-height: 48px;
                    min-width: 110px;
                    outline: 0 !important;
                    padding: 0 20px;
                }
                &.ui-state-active {
                    background-color: #fff;
                    box-shadow: 0 0 5px rgba($gray-base, .1);
                    &::after{
                        content: " ";
                        display: block;
                        position: absolute;
                        bottom: -2px;
                        background-color: #fff;
                        width: 100%;
                        height: 4px;
                    }
                }
            }
        }

        .ui-tabs-panel {
            margin-left: 0 !important;
            border-radius: 0;
            background-color: #fff;
            padding-top: 24px !important;
            padding-bottom: 24px !important;
            box-shadow: 0 0 5px rgba($gray-base, .1);
        }
    }

    /*=====================================
    =               Refferal              =
    =====================================*/
    .refferal {
        &__heading{
            font-size: 16px;
            font-weight: 500;
            margin-bottom: $grid-size * 2;
        }
        @media (min-width: $screen-sm-min) {
            max-width: $uc212-max-width;
        }
    }

    /*=====================================
    =               DELIVERY              =
    =====================================*/
    &__delivery-panel-body{
        @extend %card-default-style;
        @extend %card-padding-base;
        max-width: $delivery-max-width;
        @include this_space-between();
    }

    &-preconditions{
        border-bottom: 1px solid $table-border-color;
        padding-bottom: $grid-size * 2;
        margin-bottom: $grid-size * 2;
        @include this_space-between();

        &-bottom {
            border: 0 none;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        label {
            margin-bottom: 0;
            margin-right: 8px;
            min-width: 160px;
            font-size: 16px;
            font-weight: 500;
        }
        select,
        input[type="text"]{
            font-size: 16px;
            font-weight: 500;
            color: $text-color;
        }
        .alert{
            margin-bottom: 0;
        }
    }
    &-country-code,
    &-postal-code{
        .fieldCont {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
        }
    }
    &-country-code{
        select {
            width: 180px;
            padding: 0 0 0 10px; /* don't remove this line reset default paddings for drop-down, sometimes content is not visible. */
        }
    }
    &-postal-code{
        .PostalCode {
            width: 90px;
        }
        .PostalArea {
            // border: 1px solid $alert-success-border;
            // background-color: $alert-success-bg;
            // color: $alert-success-text;
            // border-radius: $border-radius-base;
            padding: 5px 10px;
        }
        .alert{
            margin-top: $grid-size * 2;
        }
    }
    &-delivery-date{
        display: flex;
        align-items: center;
        &__controls{
            display: flex;
            align-items: center;
            input{
                max-width: 96px;
                border: none;
                background: none !important;
                box-shadow: none;
                padding-left: 0;
                padding-right: 0;
            }
            button{
                width: 78px;
                &::before{
                    @extend %fa-calendar;
                }
            }
        }
    }

    .DeliveryOption {
        position: relative;
        display: flex;
        min-height: 48px;
        align-items: center;
        padding: 5px 15px 5px 5px;
        border: 1px solid $table-border-color;
        border-radius: $border-radius-base;
        background-color: #fff;;
        text-align: left;
        text-decoration: none;
        color: $text-color;

        &:not(:first-child) {
            margin-top: 10px;
        }

        &.selected {
            border-color: $brand-primary;
        }
        &:focus{
            @include form-control-focus;
        }
        .Name {
            flex: 1 1;
            margin-right: 15px;
            font-size: 16px;
            font-weight: 500;
        }
        .PayDelPrice {
            flex: 0 0;
            margin-left: auto;
            text-align: right;
            white-space: nowrap;
        }
        .PayDelOptImage {
            flex: 0 0;
            height: 40px;
        }
    }
    .Tooltip {
        position: absolute;
        top: 5px;
        right: 5px;
    }
    /*=====================================
    =            USER COMMENT             =
    =====================================*/
    &__comment-panel {
        max-width: $delivery-max-width;
        textarea {
            height: 50px;
        }
    }

    /*=====================================
    =               PAYMENT               =
    =====================================*/
    //Define which container to flex
    &-checkout-provider{
        &_description-outside-checkout-ui{
            display: flex;
            flex-direction: column;
            @media (min-width: $screen-sm-min) {
                flex-direction: row;
            }
        }
        &_description-inside-checkout-ui{
            #{$_this}-checkout-ui{
                display: flex;
                flex-direction: column;
                @media (min-width: $screen-sm-min) {
                    flex-direction: row;
                }
            }
        }
    }
    .provider-checkout-panel{
        @include this_space-between(3);
        @include this_provider-col-gap();
        width: 100%;
        flex-shrink: 0;
    }
    &-payment-tab-pane{
        &[data-checkout-provider="klarnaV3"]{
            #{$_this}-checkout-ui {
                width: 100%;
                flex-direction: column; //prevents collapsing to 1px width if there are no discriptions at second column
                @include this_provider-col-gap();
                @media (min-width: $screen-md-min) {
                    max-width: 50%;
                }
            }
        }
        &[data-checkout-provider="SwedbankPay"]{
            .provider-checkout-panel {
                @media (min-width: $screen-sm-min) {
                    max-width: 390px;
                }
            }
        }        
        &[data-checkout-provider="svea"]{
            #{$_this}-checkout-provider{
                align-items: center;                
                @media (min-width: $screen-sm-min) {
                    flex-direction: column;
                }
                @media (min-width: $screen-md-min) {
                    flex-direction: row;
                    align-items: initial;
                }
            }
            .provider-description{
                margin-top: 20px;
                @media (min-width: $screen-md-min) {
                    margin-top: 0;
                }
            }
            #{$_this}-checkout-ui {
                width: 100%;                
                flex-direction: column; //prevents collapsing to 1px width if there are no discriptions at second column
                @include this_provider-col-gap();
                margin-right: 0;
                max-width: 630px;
                @media (min-width: $screen-md-min) {
                    margin-right: 32px;
                }                
                @media (min-width: $screen-lg-min) {
                    
                }
            }
        }
        //&[data-checkout-provider="vipps"]{
        //}
        //&[data-checkout-provider="paypal"]{
        //}
    }

    .provider-description{
        margin-top: 20px;
        > * {
            &:not(:first-child){
                padding-top: $grid-size * 3;
            }
        }

        @media (min-width: $screen-sm-min) {
            margin-top: 0;
        }

        &__logo{
            &_Svea{
                height: 18px;
            }
        }
        &__about{
            font-weight: 500;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            &-logo{
                &_Klarna{
                    height: 10px;
                }
                &_Vipps{
                    height: 20px;
                }
                &_Svea{
                    height: 14px;
                }
            }
            a{
                text-decoration: underline;
                margin-left: $grid-size;
            }
        }
        &__payments{
            margin-top: 8px;
        }
        &__logos{
            margin-top: 14px;
            @extend %u-list-unstyled;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 4px;
            li{
                position: relative;
                padding: $grid-size*0.5 10px $grid-size*0.5;
                display: flex;
                align-items: center;
                &:first-child{
                    padding-left: 0;
                }
                &:not(:last-child){
                    &::after{
                        content: "";
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        width: 1px;
                        height: 16px;
                        background-color: $table-border-color;
                    }
                }
            }
        }
        &__logo{
            &_Klarna{
                height: 1.6rem;
            }

            &_invoice{
                height: 2.7rem;
            }
            
            &_vipps{
                height: 1.6rem;
            }

            &_visa {
                height: 1.6rem;
            }

            &_mastercard {
                height: 2rem;
            }

            &_Vipps {
                height: 1.6rem;
            }
        }
    }
    .provider-description-total-price-container{
        border-top: 1px solid $table-border-color;
        font-weight: 500;
        
        &__price{
            &-value{
                font-size: 20px;
            }
        }
    }
    /*=====================================
    =           PAYMENT: PAYPAL           =
    =====================================*/
    .PayPalDetailsColumn{
        @include this_provider-col-gap();
        width: 100%;
        @media (min-width: $screen-sm-min) {
            max-width: 390px;
        }
    }
    .PayPalShippingTitle{
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 1em;
    }
    .PayPalShippingContainer {
        ul {
            @extend %u-list-unstyled;
        }
    }
    .PayPalShippingAddressDetails{
        @include this_space-between(2);
    }

    /* SECOND COLUMN */
    .PayPalPaymentMethod{
        margin-top: 20px;
        @media (min-width: $screen-sm-min) {
            margin-top: 0;
        }
        a{
            font-weight: 500;
            text-decoration: underline;
        }
        .PayPalDescriptionContainer{
            margin-top: $grid-size;
        }
    }

    .PayPalTotalCheckout{
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        font-weight: 500;
        margin-top: $grid-size;
        padding-top: 18px;
        border-top: 1px solid $table-border-color;
        .PayPalTotalPriceValue{
            font-size: 20px;
        }
        .IncludingShippingInfo{
            font-size: 12px;
            margin-left: $grid-size;
        }
    }
    .PayPalSubmitContainer{
        margin-top: 18px;
    }
    .PayPalCheckoutButton{
        width: 100%;
        @extend %u-btn-primary;
        @extend %u-btn-size-large;
    }
    /*=====================================
    =           PAYMENT: VIPPS           =
    =====================================*/
    .VippsShippingColumn{
        @include this_provider-col-gap();
        width: 100%;
        @media (min-width: $screen-sm-min) {
            max-width: 390px;
        }
    }
    .VippsShippingTitle{
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 1em;
    }
    .VippsShippingAddressDetails{
        @include this_space-between(2);
    }
    .VippsPaymentColumn{
        margin-top: 18px;
        padding-top: $grid-size;
        border-top: 1px solid $table-border-color;
        > * {
            margin-top: $grid-size * 2 ;
        }
    }
    .VippsTotalPriceContainer{
        font-weight: 500;
        .VippsTotalPriceValue{
            font-size: 20px;
        }
    }
    .VippsCheckoutButton{
        @extend %u-focus-outline-bordered;
    }    
}