.c-quantity-plus-minus {
    $_btn-size: 34px !default; //default bs input

    display: inline-flex;
    align-items: center;

    input {
        flex: 0 0 auto;
        height: $_btn-size;
        width: $_btn-size;
        padding: 0;
        margin-left: $grid-size;
        margin-right: $grid-size;
        text-align: center;
    }
    button{
        flex: 0 0 auto;
        height: $_btn-size;
        width: $_btn-size;
        padding: 0;
        font-size: 10px;
        &.disabled,
        &.disabled:hover {
            opacity: 0.4;
        }
        &[data-action="decrease"]{
            &::before{
                @extend %fa-minus;
            }
        }
        &[data-action="increase"]{
            &::before{
                @extend %fa-plus;
            }
        }
    }
}