.SiteCartPanel {
    position: relative;
}

.PopupContainer {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 3; /* z-index more then .nav-lcp-ex, .search button icon */
    width: 300px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba($cl_Black, .2);
    background: $cl_White;
    display: none;

    @media (min-width: $screen-xs-min) {
        display: block;
    }
}

.site-cart-popup {

    &-free-shipping {
        $self: &;
        margin-bottom: 1.5rem;
        text-align: center;
        &__progress {
            margin-bottom: .5rem;
        }
        &__message {
            font-size: 1.3rem;
        }
        &_reached {
            #{$self}__progress {
                display: none;
            }
            #{$self}__message {
                color: $brand-success;
                &::before {
                    vertical-align: -1px;
                    margin-right: .4em;
                    @extend %un-check;
                }
            }
        }
    }

    &__button {
        height: 4rem;
    }

    &__export-to-excel {
        @extend .btn-block;
        @extend %u-btn;
        @extend %u-btn-default;
    }
    &__go-to-shopcart {
        @extend .btn-block;
        @extend %u-btn;
        @extend %u-btn-primary;
    }

    &-item:not(:first-child),
    &-products-in-cart,
    &-summary {
        //margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid $table-border-color;
    }

    &-summary {
        margin-top: 1.5rem;
    }

    &-products-in-cart {
        margin-top: .9rem;

        &__label {
            font-weight: 500;
        }
    }

    &__items {
        max-height: 350px;
        overflow: auto;
        overscroll-behavior-y: contain;
        margin: 0 -1.5rem;
        padding: 0 3.7rem 0 1.5rem;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: $cl_Wild_Sand2;
        }

        &::-webkit-scrollbar{
            width: .6rem;
            background-color: $cl_Wild_Sand2;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $cl_Black;
        }
    }

    &-item {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-top: .1rem solid $cl_Alto2;
        padding-top: 1.9rem;
        margin-top: 1.5rem;

        &__image-container {
            width: 70px;
        }
        &__details {
            position: relative;
            width: calc(100% - 70px - 15px);
        }
        &__title {
            margin-top: 0;
            font-size: $font-size-base;
            padding-right: 30px; /* space for remove button */
            overflow: hidden;

            &_link {
                @extend %u-text-d-to-l;
                font-size: 1.4rem;
                font-weight: 500;
            }
        }
        &__row {
            font-size: $font-size-base - 2px;
        }
        &__key,
        &__value {
            display: inline-block;
        }
        &__key {
            min-width: 60px;
        }
        &__remove {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 8px;
            padding: 0.6em;
            display: inline-block;
            cursor: pointer;
            @extend %u-btn;
            &::before {
                @extend %un-close2;
            }
        }
        &__quantity {
            &-row_editable {
                margin-top: .5rem;
                font-size: 0;
            }
            &-row_editable &-key {
                display: none;
            }
            &-input,
            &-adjuster {
                display: inline-block;
                vertical-align: middle;
            }
            &-input {
                width: 22px;
                height: 22px;
                margin: 0 3px;
                border: 0;
                font-size: 14px;
            }
            &-adjuster {
                width: 22px;
                height: 22px;
                padding: 0;
                font-size: 10px;
            }
        }

        &:first-child {
            border-top: 0;
            margin-top: 0;
        }
    }

    &-products-in-cart {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__count {
            padding-right: 4px;
        }
        &__remove-all {
            color: $cl_Coral_Red;
            text-decoration: underline;
            font-weight: $label-font-weight;
            &:hover {
                color: $cl_Persian_Red;
            }
        }
    }

    &-summary {
        width: 100%;
        border-bottom: .1rem solid $cl_Alto2;
        padding-bottom: 1.9rem;

        &__row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.3rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__key {
            flex: 2;
            font-weight: 500;
        }

        &__value {
            flex: 4;
        }

        &__price{
            color: $txt-price-color;
            font-weight: 500;

            &_vat{
                font-size: 0.8em;
            }
        }
    }

    &__empty-cart {
        text-align: center;
    }

}
