.site-underheader {

    .smart-search {

        $_height: 40px;
        $_btn-size: 22px;

        width: auto;
        margin-left: -$grid-gutter-width * 0.5;
        margin-right: -$grid-gutter-width * 0.5;

        &__input {
            height: $_height;
            padding-left: $grid-gutter-width * 0.5;
            padding-right: $_btn-size + $grid-gutter-width;
            border: 0;
            border-radius: 0;
            background-color: $cl_Wild_Sand1;
            color: $text-color;

            &:focus {
                box-shadow: none; // remove glow with brand-primary color
            }

            &::placeholder {
                color: $text-color;
            }
        }

        &__btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            height: $_height;
            padding-left: $grid-gutter-width * 0.5;
            padding-right: $grid-gutter-width * 0.5;
            font-size: $_btn-size;
            color: $text-color;
            i {
                display: none; // hide default search icon
            }
            &::before {
                @extend %un-search-slim;
            }
        }

        .tt-menu {
            right: 0;
            max-width: none;
        }

    }

}