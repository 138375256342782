$enable-site-footer__payment-images-new: false !default;

.site-footer__payment-images{
    margin-top: $site-footer-payment-info-space-top;
    &.text-center{
        .ListContainer{
            justify-content: center;
        }
        .MainTitleContainer{
            justify-content: center;
        }
    }
    &.text-xs-center{
        @media (max-width: $screen-xs-max) {
            .ListContainer{
                justify-content: center;
            }
            .MainTitleContainer{
                justify-content: center;
            }
        }
    }
    &.text-sm-left{
        @media (min-width: $screen-md-min) {
            .ListContainer{
                justify-content: left;
            }
            .MainTitleContainer{
                justify-content: left;
            }
        }
    }
    &.text-sm-center{
        @media (min-width: $screen-md-min) {
            .ListContainer{
                justify-content: center;
            }
            .MainTitleContainer{
                justify-content: center;
            }
        }
    }
    .MainTitleContainer{
        display: flex;
    }
    .ListContainer{
        align-items: center;
        flex-wrap: wrap;
        @if($enable-site-footer__payment-images-new == false) {
            display: inline-flex;
        } @else {
            display: flex;
        }

    }
    &_cards{
        .UC188{
            display: flex;
            flex-direction: column;
        }
        .ListContainer{
            margin: 0 -2px;
        }
        .Item{
            background-color: $cl_White;
            min-width: 80px;
            min-height: 40px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 2px;
            padding: 0 8px;
        }
    }
    &_bordered-items{
        .UC188{
            display: flex;
            flex-direction: column;
        }
        .ListContainer{
            flex-wrap: wrap;
            

            @if($enable-site-footer__payment-images-new == false) {
                margin: 0 -2px;
            } @else {
                gap: .6rem;
                
                @media (min-width: $screen-md-min) {
                    gap: .8rem;
                }
            }
        }
        .Item{
            border-width: .1rem;
            border-style: solid;
            align-items: center;
            justify-content: center;
            padding: 0 .8rem;

            @if($enable-site-footer__payment-images-new == false) {
                min-width: 8rem;
                display: inline-flex;
                margin: .2rem;
                border-color: rgba($cl_White, .2);
                min-height: 4rem;

            } @else {
                display: flex;
                min-height: 3.5rem;

                @media (min-width: $screen-md-min) {
                    min-height: 4rem;
                }
            }
        }
    }
    &_framed-block{
        .UC188{
            padding: 0 8px 2px;
            border: 1px solid $table-border-color;
        }
        .MainTitleContainer{
           justify-content: flex-start;
        }
        .MainTitle{
            background-color: $site-footer-bg;
            padding: 0 8px;
            display: inline-flex;
            margin-bottom: 9px !important;
            margin-top: -.5em !important;
        }
        .Item {
            padding: 8px;
        }
    }
    &_frame-gray{
        .Item{
            border-color: rgba($cl_Black, .1);
        }
    }
}