button {
    &,
    i {
       @extend %u-transition-colors; 
    }

    &:focus {
        outline: none;
    }
}

.btn {
    @extend %u-btn-size-small;

    &-default {
        text-transform: $u-btn-text-transform;
        @include uni-button-advanced-hover($u-btn-default-color, $u-btn-default-bg, $u-btn-default-border, $u-btn-default-color-hover, $u-btn-default-bg-hover, $u-btn-default-border-hover);
    }

    &-primary {
        text-transform: $u-btn-text-transform;
        @include uni-button-advanced-hover($u-btn-primary-color, $u-btn-primary-bg, $u-btn-primary-border, $u-btn-primary-color-hover, $u-btn-primary-bg-hover, $u-btn-primary-border-hover);
    }

    &-lg {
        @extend %u-btn-size-large;
    }

    &-buy {
        min-width: $u-buy-btn-min-width;
        @extend %u-btn-primary;
    }

    &-read-more {
        min-width: $u-buy-btn-min-width;
        @extend %u-btn-default;
    }

    &-icon {
        background-color: transparent;
        padding: 0;
        width: 3.2rem;
        height: 3.2rem;
        line-height: 3.2rem;

        &:focus,
        &:active {
            box-shadow: none;
        }
    }
}

.FormButton {
    @extend %u-btn;
    @extend %u-btn-size-small;
}