.UC141 {

    //more specific to structure in case main title moved by JS to Content
    > .MainTitleContainer > .MainTitle {
        &::after {
            margin-top: -5px;
            position: absolute;
            top: 50%;
            right: 10px;
            font-size: 10px;
            @extend %fa-chevron-down;
        }
    }

    .DescriptionContainer {
         @include article-content;
         @include article-formating;
    }

    img{
        @media (max-width: $screen-xs-max){
            width: 100%;
            height: auto;
        }
    }
    &.noContent {
        > .MainTitleContainer > .MainTitle:after {
            display: none;
        }
    }
    &.slideContent {
        @media (min-width: $screen-sm-min) {
            .MainTitleContainer {
                pointer-events: none;
            }
            > .MainTitleContainer > .MainTitle:after {
                display: none;
            }
            .Content {
                display: table !important;
            }
        }
        @media (max-width: $screen-xs-max) {
            > .MainTitleContainer > .MainTitle{
                margin-bottom: 0;
                font-size: 20px;
            }
            .MainTitleContainer {
                padding: 1rem;
                position: relative;
                border: 1px solid $cl_Gray_Nurse1;
            }
            .Content {
                display: none;
                padding: 1.5rem;
                margin-top: -1px;
                border: 1px solid $cl_Gray_Nurse1;
            }
            .DescriptionContainer {
                &:not(:first-child) {
                    margin-top: 1.5rem;
                }
            }
        }
    }

    @media (min-width: $screen-sm-min) {
        .Content {
            display: table;
            > div {
                display: table-cell;
                vertical-align: top;
            }
            .ImageContainer {
                padding-right: 2rem;
            }
        }
    }
}