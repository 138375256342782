$uc210-footer__height: 29px !default;

$uc210-footer-input__border-bottom-color: $gray !default;
$uc210-footer-input__font: 14px 400 normal !default;
$uc210-footer-input__color: $input-color !default;

$uc210-footer-placeholder__font: 14px 400 !default;
$uc210-footer-placeholder__color: $input-color-placeholder !default;

$uc210-footer-title__color: $brand-primary !default;
$uc210-footer-title__size: 1.4rem !default;
$uc210-footer-title__transform: uppercase !default;
$uc210-footer-title__font-weight: 700 !default;
$uc210-footer-title__space-bottom: 1.4rem !default;

$uc210-footer-btn__space: 1.5rem !default;
$uc210-footer-btn__padding: 0 16px !default;
$uc210-footer-btn__radius: $btn-border-radius-base !default;
$uc210-footer-btn__font: 14px 500 normal !default;
$uc210-footer-btn__style: "%u-btn-default" !default;

$uc210-footer-privacy-policy__space: 1.5rem !default;
$uc210-footer-privacy-policy__font: 1.2rem 400 normal !default;
$uc210-footer-privacy-policy__color: $cl_White !default;

$uc210-footer-privacy-policy-link__font: 1.2rem 500 normal !default;
$uc210-footer-privacy-policy-link__color: $uc210-footer-privacy-policy__color !default;
$uc210-footer-privacy-policy-link__color-hover: $text-color-gray !default;

.site-footer {

    .subscribe-newsletter {
        
        h1 {
            color: $uc210-footer-title__color;
            font-size: $uc210-footer-title__size;
            margin-bottom: $uc210-footer-title__space-bottom;
            text-transform: $uc210-footer-title__transform;
            font-weight: $uc210-footer-title__font-weight;
        }

        &__controls {
            display: flex;
        }

        &__input {
            flex: 1 1;
        }

        &__input {
            height: $uc210-footer__height;
            padding: 0;
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid $uc210-footer-input__border-bottom-color;
            box-shadow: none;
            background: none;
            color: $uc210-footer-input__color;
            @include font-properties($uc210-footer-input__font);
            &:focus {
                box-shadow: none;
                border-bottom-color: $uc210-footer-input__border-bottom-color;
            }
            &::placeholder {
                color: $uc210-footer-placeholder__color;
                @include font-properties($uc210-footer-placeholder__font);
            }
        }

        &__btn {
            height: $uc210-footer__height;
            margin-left: $uc210-footer-btn__space;
            padding: $uc210-footer-btn__padding;
            border-radius: $uc210-footer-btn__radius;
            text-transform: uppercase;
            @extend %u-btn;
            @if $uc210-footer-btn__style {
                @extend #{$uc210-footer-btn__style};
            }
            @include font-properties($uc210-footer-btn__font);
        }

        &__privacy-policy {
            margin-top: $uc210-footer-privacy-policy__space;
            color: $uc210-footer-privacy-policy__color;
            @include font-properties($uc210-footer-privacy-policy__font);
        }

        &-privacy-policy {
            &__link {
                text-decoration: underline;
                color: $uc210-footer-privacy-policy-link__color;
                @include font-properties($uc210-footer-privacy-policy-link__font);
                &:hover{
                    color: $uc210-footer-privacy-policy-link__color-hover;
                }
            }
        }

    }
}