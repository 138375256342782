input {
    @extend %u-transition-colors;
}

/* Custom fields */
.customFieldCont {
    margin: 2px 0;
    .RequiredField {
        margin: 0 2px 0 -7px;
    }
}

.customFieldLabel {
    display: inline-block;
    min-width: 60px;
}
/*_Custom fields */

.MakeOrderContainer { //is used only UC170, UC169
    display: flex;
    &:before,
    &:after{
        display: none;
    }
    .QuantityContainer{
        margin-right: $u-quantity-margin-right;
    }
}

@import "e-inputs";