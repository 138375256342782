$slider-menu: () !default;
$slider-menu: map-merge(
    (
        "top-box-xs-bg":                   $link-color,
    ),
    $slider-menu
);
.SliderMenu {
    .TopBox {
        &::before,
        &::after {
            display: none;
        }
        &::after {
            @extend %fa-chevron-down;
        }
    }
    &.DocumentsIcon .TopBox::before {
        @extend %un-bars-slim;
    }
    &.ProductsIcon .TopBox::before {
        @extend %un-bars-slim;
    }

    @media (max-width: $screen-xs-max) {
        .TopBox {
            position: relative;
            padding-right: 44px;
            border-radius: $border-radius-base;
            border-bottom: none;
            background-color: map-get($slider-menu, "top-box-xs-bg");
            cursor: pointer;
            &::after {
                right: 10px;
                font-size: 10px;
                transform: rotate(0);
                transition: transform $animation-duration;
            }
            &::before,
            &::after {
                position: absolute;
                top: 50%;
                display: block;
                width: 24px;
                height: 24px;
                line-height: 24px;
                margin-top: -12px;
                visibility: visible;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                text-align: center;
                color: $cl_White;
            }
        }
        .MenuTitle,
        .MainTitle {
            text-align: center;
            color: $cl_White;
        }
        .MiddleBox {
            max-height: 0;
            padding: 0;
            overflow: hidden;
            transition: max-height $animation-duration;
        }
        &.clicked {
            .TopBox {
                &::after {
                    transform: rotate(180deg);
                }
            }
            .MiddleBox {
                max-height: 5000px;
            }
        }
        &.DocumentsIcon {
            .TopBox {
                padding-left: 44px;
                &::before {
                    left: 10px;
                    font-size: 16px;
                }
            }
        }
        &.ProductsIcon {
            .TopBox {
                padding-left: 44px;
                &::before {
                    left: 10px;
                    font-size: 19px;
                }
            }
        }
    }
}
