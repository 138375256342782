$site-cart-popup-prod-count__color: $cl_White !default;
$site-cart-popup-prod-count__background: $brand-primary !default;
$site-cart-popup__blink-animation: false !default;
$site-cart-popup__blink-color: $brand-primary !default;

@keyframes blink-cart {
    0% {
        background-color: inherit;
    }
    25% {
        background-color: $site-cart-popup__blink-color;
    }
    50%{
        background-color: inherit;
    }
    75%{
        background-color: $site-cart-popup__blink-color;
    }
    100% {
        background-color: inherit;
    }
  }

.SiteCartPanel {
    $_prod-count-width: 1.7rem;
    .TitleContainer,
    .LoginRegisterPanel,
    .PofileLogoutPanel,
    .ShowCartTxt,
    .TotalPriceContainer,
    svg {
        display: none;
    }

    .SiteCartIcon {
        @extend %flex;
        @extend %flex-item-center;
        position: relative;
        pointer-events: none;
        font-size: 32px;
        text-align: center;

        &::before {
            @extend %un-site-cart3;
        }
    }

    .ShowCartLink {
        @extend %flex;
        @extend %flex-item-center;
        position: relative;
        text-decoration: none;  
        @if($site-cart-popup__blink-animation){
            &.is-bought{
                animation: blink-cart 1s linear;                
            }
        }
    }

    .ProdCount {
        background-color: $site-cart-popup-prod-count__background;
        transition: transform $animation-duration;
        color: $site-cart-popup-prod-count__color;
        @include font-properties(1.1rem 700 normal);
        @include u-sticker-circle($_prod-count-width);
        @extend %u-sticker;
    }
}
.site-header-desktop{
    .ProdCount {
        @include u-sticker-position(-3px auto auto 10px);
    }
    .ShowCartLink{
        &:hover,
        &:focus {
            .ProdCount {
                transform: translateY(3px);
            }
        }
    }
}
.site-header-mobile{
    .ProdCount {
        top: 50%;
        left: 50%;
        transform: translate3d(-10px, -17px, 0);
    }
}