@media print {
    //Bootstrap style print
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: $cl_Black !important; // Black prints faster: h5bp.com/s
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    // Don't show links that are fragment identifiers,
    // or use the `javascript:` pseudo protocol
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid $cl_Mountain_Mist2;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; // h5bp.com/t
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    // Bootstrap specific changes start

    // Bootstrap components
    .navbar {
        display: none;
    }
    .btn,
    .dropup > .btn {
        > .caret {
            border-top-color: $cl_Black !important;
        }
    }
    .label {
        border: 1px solid $cl_Black;
    }

    .table {
        border-collapse: collapse !important;

        td,
        th {
            background-color: $cl_White !important;
        }
    }
    .table-bordered {
        th,
        td {
            border: 1px solid $cl_Alto1 !important;
        }
    }

    // Custom Style Print

    .mn-sitebar {
        &__button {
            display: none;
        }
    }

    .site-header {
        &__content {
            text-align: center;

            a {
                display: inline-block;
                float: none !important;
            }
        }
    }

    #cookie-bar,
    #crumbs_list,
    .WishListBtn,
    .hide-at-checkout,
    .profiler-results,
    .print-hide,
    .l__aside {
        display: none !important;
    }

    .page-home {
        &__megabutton-container {
            display: none;
        }
    }

    .site-footer {
        &__social-icons {
            display: none;
        }
    }
}
