/*Used for menu controls initialized at default.aspx
Horizontal product menu: Same view for all screen sizes */
.site-horizontal-menu {
    ul {
        display: block;
        @extend %u-list-unstyled;
    }
    li {
        display: block;
        position: relative;
    }
    a {
        display: block;
        text-decoration: none;
    }

    .Toggle {
        width: 20px;
        position: absolute;
        top: 1px; /* fix for design alignment, actually 0px needed */
        bottom: 0;
        right: 5px;
        font-size: 7px;
        text-align: center;
        &::before {
            @extend %fa-chevron-down;
        }
    }
    .Level-1 {
        position: relative;
        z-index: 1;
        display: flex;
        flex-wrap: wrap;

        ul {
            display: none;
            min-width: 100%;
            position: absolute;
            z-index: 2;
            background-color: $cl_White;
            padding-top: $grid-size;
            padding-bottom: $grid-size;

            ul {
                margin-top: -$grid-size;
            }
        }
        > li {
            &:not(:last-child) {
                margin-right: 22px;
            }
            > a {
                line-height: $PM-line-height;
            }
            > .Name {

            }
            > .Toggle {
                display: none;
            }
        }
    }
    .Level-2 {
        top: 100%;
        left: 0;
        ul {
            top: 0;
            left: 100%;
        }
        .Toggle {
            color: $text-color;
            &::before {
                @extend %fa-chevron-right;
            }
        }
        .Name,
        .Toggle {
            line-height: $PM-sublevel-line-height;
        }
        .Name {
            padding-left: 14px;
            padding-right: 14px;
            white-space: nowrap;
            @extend %u-text-d-to-l;
        }
        .Selected {
            > .Name {
                font-weight: 500;
                text-decoration: underline;
            }
        }
        .Expandable,
        .Expanded {
            .Name {
                padding-right: 26px;
            }
        }
    }
    &.Expandable {
        .Expandable,
        .Expanded {
            &:hover {
                > ul {
                    display: block;
                }
            }
        }
    }
    &.NotExpandable {
        .Toggle {
            display: none;
        }
        .Level-2 {
            display: none !important;
        }
    }
}