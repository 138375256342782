@import "m-type";
@import "e-type";

a {
    @extend %u-transition-colors;
}

// .MainTitleContainer,
// .KlarnaCheckout .MainTitle, // here is no MainTitleContainer
// .UC110MainContainer .MaintTitle, // here is no MainTitleContainer
//article header,
h1 {
    @extend %u-heading;
}

// td.MainTitleContainer { // UC110 - detailed cart
//     padding-bottom: $heading-h1-mb;
//     margin-bottom: 0;
// }
span.MainTitle,
a.MainTitle{
    display: inline-block;
    @extend %u-heading-non-h-tag;
}

// span.MainTitle - UC110 - detailed cart
// a.MainTitle - UC110 - detailed cart, shoppinglist title but without href attr
// span.MainTitle - UC110 - detailed cart, there is also h2.MainTitle in NoProducts block

.MainTitle{
//.SubMainTitle
    @extend %u-heading-h1;
}

// .MainTitle, // h1.MainTitle, h2.MainTitle, div.MainTitle, span.MainTitle, a.MainTitle
// .SubMainTitle, // h2.SubMainTitle
// article header h1 {
//     margin-top: 0;
// }


/* List titles
 * UC174 - List child documents
 * UC175 - Document list
 * UC193 - Product list
*/
.ListItemTitleCont,
.ItemTitleContainer {
    overflow: hidden;
    @extend %u-transition-colors;
}

.ListItemTitle,
.ItemTitleLink {
    &:hover {
        text-decoration: none;
    }
}

.UC174,
.UC175,
.UC193default {
    .ItemTitle {
        @extend %u-text-inherit;
    }
}