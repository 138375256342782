// &-shopping-lists
// &-shopping-list-details
// &-shopping-list _shared - title is not approved

&__enviromental-fee-icon{
    color: $brand-info;
}

/*----------  SHOPPING LIST SHARED DETAILS VIEW  ----------*/
&-share-dialog {
    & > div:not(:first-child){
        margin-top: 14px;
    }
    &__share-link-label,
    &__email-form-label {
        font-size: $font-size-h4;
    }
    hr {
        display: none;
    }
    &__contact-form {
        &-button{
            width: 100%;
        }
    }
}
/*---------- KEYBOARD NAVIGATION  ----------*/
&__keyboard-support-tips{
    margin-top: 20px;
}
&-shopping-list-details-action-buttons{
    margin-bottom: 20px;
    .fa{
        margin-right: 4px;
    }
    &-holder{
        display: flex;
        flex-direction: column;
        @media (min-width: $screen-sm-min) {
            display: block;
            flex-direction: row;
        }
        &_top{
            .btn{
                &:not(&:first-child){
                    margin-top: $grid-size;
                }
                @media (min-width: $screen-sm-min) {
                    &:not(&:first-child){
                        margin-top: 0;
                    }
                }
            }
        }
    }
}