////
/// @group un
////

/// uniicon (icons font) default styles
%un {
    font-family: "uniicons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


/// check icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-check;
///        }
///    }
%un-check {
    @extend %un;
    content: "\E902";
}


/// key icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-key;
///        }
///    }
%un-key {
    @extend %un;
    content: "\E90A";
}


/// scrollbar-horizontal icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-scrollbar-horizontal;
///        }
///    }
%un-scrollbar-horizontal {
    @extend %un;
    content: "\E91D";
}


/// site-cart3-plus icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-site-cart3-plus;
///        }
///    }
%un-site-cart3-plus {
    @extend %un;
    content: "\E921";
}


/// site-cart3 icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-site-cart3;
///        }
///    }
%un-site-cart3 {
    @extend %un;
    content: "\E922";
}


/// site-cart3-empty icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-site-cart3-empty;
///        }
///    }
%un-site-cart3-empty {
    @extend %un;
    content: "\E923";
}


/// close2 icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-close2;
///        }
///    }
%un-close2 {
    @extend %un;
    content: "\E924";
}


/// user-slim icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-user-slim;
///        }
///    }
%un-user-slim {
    @extend %un;
    content: "\E932";
}


/// hand-bag-slim icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-hand-bag-slim;
///        }
///    }
%un-hand-bag-slim {
    @extend %un;
    content: "\E933";
}


/// search-slim icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-search-slim;
///        }
///    }
%un-search-slim {
    @extend %un;
    content: "\E934";
}


/// bars-slim icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-bars-slim;
///        }
///    }
%un-bars-slim {
    @extend %un;
    content: "\E936";
}


/// file-list icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-file-list;
///        }
///    }
%un-file-list {
    @extend %un;
    content: "\E937";
}
