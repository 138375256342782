.uc110-discount-codes{
    max-width: 285px;

    &__heading{
        @extend %u-heading-h2;
        margin-bottom: $grid-size;
    }
    &__input{
        display: inline-block;
        margin-right: 10px;
        width: calc(100% - 90px);
        vertical-align: middle;
        &-group{
            display: flex;
        }
    }
    &__btn{
        width: 80px;
        height: $input-height-base;
    }
    // &__list{
    // }
    &__coupon{
        font-size: 14px;
        line-height: 20px;
        margin-top: 0;
        &-code{
            &:before{
                content: "\"";
            }
            &:after{
                content: "\"";
            }
            margin-right: 5px;
        }
        &-amount{
            &:before{
                margin-right: 5px;
            }
        }
        &.ForDelivery {
            position: relative;
            padding: 6px 30px 6px 8px;
            background-color: rgba($brand-primary, .15);
            border: 1px solid $brand-primary;
            &::before {
                @extend %fa-truck;
                transform: translate3d(0, -50%, 0);
                position: absolute;
                top: 50%;
                right: 8px;
                font-size: 20px;
                color: $brand-primary;
            }
        }
    }
    &__discount-name{
        &:after{
            content: ":";
        }
        margin-right: 5px;
    }
}