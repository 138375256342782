#fancybox-wrap { /*DO NOT DELETE :) */
    &, & * {
        box-sizing: content-box;
    }
}
#fancybox-overlay{
    background-color: rgb(0, 0, 0) !important;
}

.ui-slider-range{
    .ui-state-active{
        border-color: $brand-primary;
        color: $brand-primary;
    }
}

//.ui-datepicker changes
.ui-datepicker-title{
    display: flex;
    justify-content: space-between;
    select{
        padding: 0 4px;
    }
}
.ui-datepicker-header{
    .ui-state-hover{
        border: none;
    }
}
.ui-datepicker-prev,
.ui-datepicker-next{
    margin-top: 6px;
}
.ui-datepicker-calendar{
    .ui-state-default{
        border-radius: 4px;
    }
    .ui-state-active{
        border-color: $brand-primary;
        background-color: $brand-primary;
        color: $cl_White;
    }
}

.ui-widget-header{
    border: none;
    border-radius: 0;
}
.ui-dialog{
    @media (max-width: $screen-sm-max){
        width: 95% !important;
    }
    &.ui-widget-content{
        border: none;
    }
    .ui-dialog-titlebar{
        padding-top: 0.8em;
        padding-bottom: 0.8em;
    }
}
.ui-dialog-title{
    @extend %u-heading-h3;
    @extend %u-heading-non-h-tag;
}
.ui-dialog-titlebar-close{
    @extend %u-btn;
    background: none;
    width: 32px !important;
    height: 32px !important;
    margin-top: 0 !important;
    top: 4px !important;
    right: 4px !important;
    border-radius: 50%;
    box-shadow: 0 0 .5rem rgba($color: $cl_Black, $alpha: .5);
    background-color: $cl_White;
    
    &::before{
        @extend %fa-times;
    }
}
.ui-effects-transfer { /* effect on product buy DO NOT DELETE - linked with backend functions*/
    border: 1px dotted black;
}