.umw-bonus-to-spend{
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-sm-min){
        flex-direction: row;
        justify-content: space-between;
    }

    &__heading{
        margin-top: 0;
        @extend %bonus-system-before-flag;
    }
    &__info-panel{
        @extend %card-default-style;
        @extend %card-padding-base;
        background-color: $bonus-success-color;
        &.no-active-bonuses{
            background-color: transparent;
        }
        @media (min-width: $screen-sm-min){
            min-width: 424px;
        }
    }
    &__no-bonus-alert{
        margin-bottom: 0 !important;
    }
    &__input-group{
        display: flex;
        align-items: center;
    }
    &__input{
        display: inline-flex;
        flex: 1 1 auto;
    }
    &__apply-btn {
        margin-left: $grid-size;
    }
    &__stats{
        margin-top: $grid-size * 2;
        > div {
            margin-top: $grid-size * 0.5;
        }
    }
    &__balance-term-description,
    &__bonus-to-spend-term-description,
    &__cart-profit-term-description{
        font-weight: $label-font-weight;
        margin-left: 8px;
    }

    //Total price panel
    &__cart-info-panel{
        text-align: right;
        margin-top: $grid-size * 4;
        @media (min-width: $screen-sm-min) {
            margin-top: 0;
        }
    }
    &__loyalty{
        border-top: 1px solid $table-border-color;
        border-bottom: 1px solid $table-border-color;
        margin-bottom: $grid-size * 2;
        margin-top: $grid-size * 2;
        padding-bottom: $grid-size * 2;
        padding-top: $grid-size * 2;
    }
    &__bonus-points{
        margin-top: $grid-size;
    }
    &__coupon-code-term-description{
        &:before{
            content: "«";
        }
        &:after{
            content: "»";
        }
    }
    &__coupon-code-term-definition,
    &__product-count-term-definition,
    &__bonus-points-term-definition,
    &__total-discount-term-definition,
    &__total-sum-excl-vat-term-definition,
    &__total-vat-term-definition,
    &__total-sum-term-definition{
        font-weight: $label-font-weight;
        margin-right: 8px;
    }
    &__total-sum-term-description{
        font-size: $font-size-h2;
        font-weight: $label-font-weight;
    }
    &__total-sum-excl-vat-indicator,
    &__total-sum-vat-indicator{
        font-size: 12px;
        margin-left: 8px;
    }

    //Optional link for user to a bonus products page (is shown if the page ID was set at website settings)
    &__bonus-products-link{
        font-weight: $label-font-weight;
        text-decoration: underline;
        &-container{
            margin-top: $grid-size;
        }
    }
}