.wrp-uc129-card{
    .uc129{
        @extend %card-default-style;
        @extend %card-padding-base;
        &__title{
            font-size: $font-size-h2;
            margin-bottom: 1em;
        }
    }
}
