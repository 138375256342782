.UC193 {
    .ShowOnlyInStock{
        margin-top: 0.5rem;
        float: right;
        label{
            font-weight: normal;
        }
    }
    .dropdown {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;

        button {
            &.btn {
                display: none;
            }
        }

        &-header {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .ItemsPerPageContainer {
                display: none;
            }

            .sortingTitle {
                margin-right: 10px;
            }
        }

        &-menu {
            display: block;
            left: inherit;
            float: none;
            position: relative;
            border: 0;
            background-color: transparent;
            box-shadow: none;
            z-index: inherit;
        }
    }
    .ProductListPagerContainer{
        float: none;
        clear: both;
        &.Top {
            float: left;
            .pagination{
                margin-top: 0;
            }
        }
    }
    .ItemsPerPageContainer{
        margin-top: 20px;
    }
    .ItemsPerPageOptions{
        padding-left: 0;
        list-style: none;
    }
}