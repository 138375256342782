$uc200-source-bg-color: $cl_White !default;

.uc200{
    &__sources-container{
        margin-top: 20px;
    }
    &__filter {
        width: 100%;
        margin-bottom: 20px;
        float: left;
    }

    &__sorting {
        float: left;
    }

    &__search {
        height: 2.5em;
        padding: 0 0.8em;
        float: right;
        width: auto;
    }

    &__source {
        box-shadow: 0 0 5px rgba(0,0,0,.05);
        background-color: $uc200-source-bg-color;
        &:not(:first-child) {
            margin-top: 30px;
        }
    }

    &__source-header {
        display: none;
        padding: 0.65em 1em;
        overflow: hidden;
        background-color: $cl_Black_Haze;
    }

    &__source-heading,
    &__source-download-all {
        line-height: 26px;
    }

    &__source-heading {
        float: left;
        font-size: 18px;
    }

    &__source-download-all {
        float: right;
    }

    &__source-body {
        padding: 1em;
    }

    &__item {
        &:nth-child(odd) {
            background-color: $cl_Alabaster3;
        }
        a{
            color: $text-color;
        }

        @media (min-width: $uc200-table-transfrom-point) {
            display: table;
            width: 100%;

            > * {
                display: table-cell;
                padding: 0.8em 0.3em;
                vertical-align: middle;
            }

            table-layout: fixed;
        }
    }
    &__item-title{
        padding-left: 0.5em;
        @media (min-width: $uc200-table-transfrom-point) {
            padding-left: 1.5em;
        }
    }

    &__item-icon{
        width: 32px;
        &-img{
            width: 32px;
        }
    }

    &__item-size {
        width: 80px;
    }

    &__item-date {
        width: 80px;
    }
}