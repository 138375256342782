$uc-document-list: () !default;
$uc-document-list: map-merge(
    (
        "media-portrait": "(max-width: #{$screen-xs-min - 1})",
        "media-landscape": "(min-width: #{$screen-xs-min})",

        "card-style": "%card-default-style",

        "image-container-portrait-padding": null,
        "image-container-landscape-padding": 1rem 0 1rem 1rem,

        "title-margin-bottom": 1.2rem,
        "title-line-height": 1.5,
        "title-font": 1.6rem,

        "teaser-margin-bottom": 1.2rem,
        "teaser-line-height": 1.57,
        "teaser-font": $font-size-base,

        "content-margin-bottom": 1.2rem,
        "content-line-height": $line-height-base,
        "content-font": $font-size-base,

        "details-portrait-padding": 1.7rem 2.4rem .8rem 2.4rem,
        "details-landscape-padding": 1rem 1rem 1rem 2.4rem,
        "details-footer-portrait-gap-compensator": -1.6rem,
        "details-footer-landscape-gap-compensator": null,

        "date-font": 1.2rem,
        "date-color": $text-color-gray,

        "pagination-margin-top": 2.4rem,
    ),
    $uc-document-list
);

.uc-document-list {
    img{ width: 100%; }
    &__list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    &__item {

        display: inline-flex;
        @if map-get($uc-document-list, "card-style") {
            @extend #{map-get($uc-document-list, "card-style")};
        }

        @if map-get($uc-document-list, "media-portrait") {
            @media #{map-get($uc-document-list, "media-portrait")} {
                flex-direction: column;
                align-items: stretch;
            }
        }

        &-image-container {
            margin-bottom: auto; // always at the top, in case details container higher then image

            @if map-get($uc-document-list, "media-portrait") {
                @media #{map-get($uc-document-list, "media-portrait")} {
                    padding: map-get($uc-document-list, "image-container-portrait-padding");
                }
            }
            @if map-get($uc-document-list, "media-landscape") {
                @media #{map-get($uc-document-list, "media-landscape")} {
                    padding: map-get($uc-document-list, "image-container-landscape-padding");
                    flex: 0 0 auto;
                }
            }
        }

        &-details {
            display: flex;
            flex-direction: column;

            @if map-get($uc-document-list, "media-portrait") {
                @media #{map-get($uc-document-list, "media-portrait")} {
                    padding: map-get($uc-document-list, "details-portrait-padding");
                }
            }
            @if map-get($uc-document-list, "media-landscape") {
                @media #{map-get($uc-document-list, "media-landscape")} {
                    padding: map-get($uc-document-list, "details-landscape-padding");
                    flex: 1 1;
                }
            }
        }

        &-title {
            line-height: map-get($uc-document-list, "title-line-height");
            margin-top: 0;
            margin-bottom: map-get($uc-document-list, "title-margin-bottom");
            @include font-properties(map-get($uc-document-list, "title-font"));

            &-link {
                @extend %u-list-title-d-to-l;
            }
        }

        &-teaser {
            line-height: map-get($uc-document-list, "teaser-line-height");
            margin-bottom: map-get($uc-document-list, "teaser-margin-bottom");
            @include font-properties(map-get($uc-document-list, "teaser-font"));

            :last-child {
                margin-bottom: 0;
            }
        }

        &-content {
            line-height: map-get($uc-document-list, "content-line-height");
            margin-bottom: map-get($uc-document-list, "content-margin-bottom");
            @include font-properties(map-get($uc-document-list, "content-font"));

            :last-child {
                margin-bottom: 0;
            }
        }

        &-details-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @if map-get($uc-document-list, "media-portrait") {
                @media #{map-get($uc-document-list, "media-portrait")} {
                    margin-right: map-get($uc-document-list, "details-footer-portrait-gap-compensator");
                }
            }
            @if map-get($uc-document-list, "media-landscape") {
                @media #{map-get($uc-document-list, "media-landscape")} {
                    margin-top: auto; // always at the bottom, in case image container higher then details
                    margin-right: map-get($uc-document-list, "details-footer-landscape-gap-compensator");
                }
            }
        }

        &-date {
            color: map-get($uc-document-list, "date-color");
            @include font-properties(map-get($uc-document-list, "date-font"));
        }

        &-read-more {
            margin-left: auto; // always at the right side
        }

    }

    &__pager {
        .pagination {
            margin-top: map-get($uc-document-list, "pagination-margin-top");
        }
    }

}