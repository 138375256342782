/// Product List/List child documents column handling.
/// 99.99% width is used to fix rendering bug at Microsoft Edge.
/// @param {Int} $numPerRow [1] - Number of items per row
/// @param {%} $gap [0] - Space around item
/// @example @include row-machine(2, 2.65%);
@use 'sass:math';

@mixin row-machine ($numPerRow:#{1}, $gap:#{0}) {

    @if $numPerRow > 1 {

        width: get-column-width($numPerRow, $gap);
        &:nth-of-type(n) {
            margin-top: $gap;
            margin-right: $gap;
        }
        &:nth-of-type(#{$numPerRow}n),
        &:last-child {
            margin-right: 0;
        }
        &:nth-of-type(-n + #{$numPerRow}) {
            margin-top: 0;
        }

    } @else {

        width: 100%;
        margin-top: $gap;
        &:first-child {
            margin-top: 0;
        }

    }

}

@function get-column-width ($numPerRow, $gap) {
    // P.S.: 99.99% - special for IE ;) dont ask me why, just believe
    @if unit($gap) == '%' {
        // Edge browser has problems with value rounding process so we will delegate rounding to sass library
        // This is possible only when $gap is an percentage value
        // Currently our frontend build tool uses 'precision: 8' option. That means we will get 8 signs after dot in output (example: 16.66666666%; 33.33333333%)
        @return math.div(99.99% - ($numPerRow - 1) * $gap, $numPerRow);
    } @else {
        // We should use calc() function and interpolation when $gap is an pixel value
        @return calc((99.99% - (#{$numPerRow} - 1) * #{$gap}) / #{$numPerRow});
    }
}