.uc-product-view{
    $_default-image-size: 460px;
    //&__replacement-product-alert{}
    //&__item-front-end-editing{}
    &__product-cant-buy-reason-explanation-alert{
        margin-top: $grid-size * 2;
    }

    @import "common/product-v2";
    @import "common/additional-products-v2";
    @import "tab-markdown";

    &__product-header{
        margin-bottom: 6px;
    }

    &__info-product {
        margin-top: 1.6rem;
    }

    //Product details base layout
    &__product-images{
        @media (min-width: $screen-md-min) {
            margin-right: $grid-gutter-width;
            width: 57.19%;
        }
    }
    &__product-sidebar{
        @media (min-width: $screen-md-min) {
            width: 40%;
        }
    }
    &__product-thumbnails{
        @media (min-width: $screen-md-min) {
            margin-right: $grid-size * 2;
            flex-basis: 86px; // 'See more' button has calculated width 85.69px, so make width limit for the whole column
            &-item{
                &:nth-child(n+6){
                    display: none;
                }
            }
        }
    }
    // Elements on main image
    &__product-flags{
        .flag{
            @extend %u-flag-lg;
        }
    }
    &__product-flag-discount-percent{
        @extend %u-flag-discount-percent-lg;
    }
    //#Elements on main image
    &__product-brand-name{
        margin-top: 9px;
        font-size: $font-size-base;
        margin-bottom: 0;
    }
    &__product-rating{
        margin-bottom: 3px;
    }
    &__product-variants{
        margin-top: 26px;
        & > :not(:first-child){
            margin-top: 16px;
        }
        .c-variant-selector__variant-tile{
            min-height: $u-buy-btn-height-large;
            min-width: $u-buy-btn-height-large;
        }
    }
    &__product-editable-custom-fields{
        & > div {
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }
    &__product-add-to-cart{  //.c-add-to-cart
        margin-top: 22px;
        .c-add-to-cart{
            &-quantity-input{
                @extend %u-quantity-txb-large;
            }
            &-quantity-drop-down {
                @extend %u-quantity-drp-large;
            }
            &-factor-input,
            &-fraction-input {
                border-radius: $btn-border-radius-large;
                height: $u-buy-btn-height-large;
            }
            &-btn {
                @extend %u-btn-size-large;
            }
        }
    }
    &__product-package-buy{
        margin-top: 16px;
        &-quantity{
            @extend %u-quantity-txb-large;
        }
        &-btn{
            @extend %u-btn-size-large;
        }
    }
    &__product-price-request-btn{
        margin-top: 16px;
        @extend %u-btn-size-large;
    }
    &__product-add-to-shopping-list-btn{
        margin-top: 16px;
        @extend %u-btn-size-large;
    }
    &__product-add-to-wish-list-btn{
        margin-top: 16px;
        @extend %u-btn-size-large;
    }
    &__product-klarna-branding-widget-container{
        margin-top: 20px;
        display: flex;
    }
    &__product-tabs{
        margin-top: 32px;
    }
    &__product-print-btn{
        &::before{
            @extend %fa-print;
            margin-right: $_btn-icon-space;
        }
    }
    &__lipscore-review{
        margin-top: $grid-size * 5;
        @extend %card-default-style;
        @extend %card-padding-base;
    }
}