$u-btn-text-transform:                     uppercase !default;
$u-buy-btn-min-width:                      78px !default;
$u-buy-btn-min-width-large:                200px !default;
$u-buy-btn-height-base:                    28px !default;
$u-buy-btn-height-large:                   40px !default;
$u-buy-btn-font-size-base:                 14px !default;
$u-buy-btn-font-size-large:                16px !default;

$u-btn-primary-color:                      $cl_White !default;
$u-btn-primary-border:                     $brand-primary !default;
$u-btn-primary-bg:                         $brand-primary !default;

$u-btn-primary-color-hover:                $cl_White !default;
$u-btn-primary-border-hover:               $brand-primary-alternative !default;
$u-btn-primary-bg-hover:                   $brand-primary-alternative !default;

$u-btn-default-color:                      $brand-primary !default;
$u-btn-default-border:                     $brand-primary !default;
$u-btn-default-bg:                         $cl_White !default;

$u-btn-default-color-hover:                $cl_White !default;
$u-btn-default-border-hover:               $brand-primary-alternative !default;
$u-btn-default-bg-hover:                   $brand-primary-alternative !default;

@import "m-buttons";
@import "e-buttons";
@import "c-buttons";
