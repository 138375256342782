.site-header-desktop {

    .smart-search {

        &__input {
            border-color: $brand-primary-alternative;
        }

        &__btn {
            color: $brand-primary-alternative;
        }

        .tt-menu {
            right: 0;
            max-width: none;
        }

    }

}