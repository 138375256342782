.SearchDesktop {
    &__input {
        background-color: $cl_White;
        border-radius: 2.8rem;
    }
}

.site-horizontal-menu {
    .Level-1 {
        > li {
            > .Name {
                font-weight: 500;
                text-transform: uppercase;
                color: $cl_White;

                &::before {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 100%;
                    content: "";
                    display: block;
                    height: 1px;
                    margin-top: -7px;
                    background-color: $cl_White;
                    transition: right $animation-duration;
                }
            }

            &:hover,
            &.Selected {
                > .Name {
                    color: $cl_White;

                    &::before {
                        right: 0;
                    }
                }
            }
        }
    }
}

.site-header-mobile{
    position: fixed;

    &__toggle-underheader-btn_menu::before{
        width: 26px;
        height: 22px;
    }
    &__toggle-underheader-btn_search::before,
    & .profile-quick-menu__un-user::before{
        width: 22px;
        height: 22px;
    }
    & .profile-quick-menu__un-user{
        width: 38px;
        height: $site-header-mobile__height;
    }
    .SiteCartPanel {
        .SiteCartIcon::before{
            width: 31px;
            height: 22px;
        }
        .ShowCartLink{
            width: 47px;
            height: $site-header-mobile__height;
        }
    }
}


.site-header {

    $site-header: &;

    &-mobile {

        /* elements */
        &__logo {
            &-img {
                max-height: 100%; 
            }
        }

       

    }

}
