.uc193-default {
    display: flex;
    flex-wrap: wrap;
    .Item{
        position: relative;
        display: inline-flex;
        flex-direction: column;
    }
    .ImageContainer{
        position: relative;
    }
    .ItemTitleContainer,
    .PricesContainer{
        text-align: center;
    }
    .PricesContainer{
        padding-top: 1.8rem;
        margin-top: auto;
    }
}