.products-menu-mobile {

    $_product-menu-lvl1-link__border-separator: 1px solid $cl_Alto2;
    $_product-menu-lvl1-link__font: 16px 500 normal;
    $_product-menu-sublvl__x-offset: 8px;
    $_product-menu-sublvl-link__font: 14px 400 normal;
    $_product-menu-link__height: 40px;
    $_product-menu-link__color: $text-color;
    $_product-menu-toggle__color: $_product-menu-link__color;

    ul {
        display: block;
        position: relative;
        @extend %u-list-unstyled;
    }

    a {
        display: block;
        text-decoration: none;
    }

    .Name {
        line-height: $_product-menu-link__height;
        color: $_product-menu-link__color;
    }

    .Toggle {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: $_product-menu-link__height;
        text-align: center;
        font-size: 8px;
        transition: transform $animation-duration;
        color: $_product-menu-toggle__color;
        &::before {
            @extend %fa-chevron-down;
        }
    }


    li {
        display: block;
        position: relative;
        &.Expandable,
        &.Expanded {
            .Name {
                padding-right: 30px;
            }
        }
        &.Expanded {
            > ul {
                display: block;
            }
        }
        &.Selected {
            > .Name {
                text-decoration: underline;
            }
        }
    }

    .Expanded {
        > .Toggle {
            transform: rotate(180deg);
        }
    }

    .Expandable {
        > .Toggle {
            transform: rotate(0);
        }
    }

    .Level-1 {
        position: relative;
        z-index: 1;
        > li {
            &:not(:first-child) {
                border-top: $_product-menu-lvl1-link__border-separator;
            }
            > a {
            }
            > .Name {
                text-transform: uppercase;
                @include font-properties($_product-menu-lvl1-link__font);
            }
        }
        ul {
            display: none;
            padding-left: $_product-menu-sublvl__x-offset;
        }
    }

    .Level-2 {
        a {
        }
        .Name {
            @include font-properties($_product-menu-sublvl-link__font);
        }
        .Selected {
            > .Name {
                font-weight: 500;
            }
        }
    }

}