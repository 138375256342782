$uc-tags-menu: () !default;
$uc-tags-menu: map-merge(
    (
        "item-space": .4rem,
        "link-font": 1.3rem 500 normal,
        "link-padding": .3rem 1rem,
        "link-border": 1px solid, // without color, just border-width and border-style
        "link-border-radius": $btn-border-radius-base,
        "link-border-color": $btn-default-border $btn-primary-border $btn-primary-border, // common, hovered, selected
        "link-background-color": $btn-default-bg $btn-primary-bg $btn-primary-bg, // common, hovered, selected
        "link-color": $btn-default-color $btn-primary-color $btn-primary-color, // common, hovered, selected
    ),
    $uc-tags-menu
);

.uc-tags-menu {

    &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        //margin: map-get($uc-tags-menu, "item-space") * -1;
        gap: map-get($uc-tags-menu, "item-space");
        @extend %u-list-unstyled;
    }

    &__item {
        //margin: map-get($uc-tags-menu, "item-space");
    }

    &__item-link {
        display: block;
        padding: map-get($uc-tags-menu, "link-padding");

        border: map-get($uc-tags-menu, "link-border");
        border-radius: map-get($uc-tags-menu, "link-border-radius");

        border-color: nth(map-get($uc-tags-menu, "link-border-color"), 1);
        background-color: nth(map-get($uc-tags-menu, "link-background-color"), 1);
        color: nth(map-get($uc-tags-menu, "link-color"), 1);

        @include font-properties(map-get($uc-tags-menu, "link-font"));

        &:hover,
        &:focus {
            text-decoration: none;

            border-color: nth(map-get($uc-tags-menu, "link-border-color"), 2);
            background-color: nth(map-get($uc-tags-menu, "link-background-color"), 2);
            color: nth(map-get($uc-tags-menu, "link-color"), 2);
        }
    }

    &__item_selected &__item-link {
        border-color: nth(map-get($uc-tags-menu, "link-border-color"), 3);
        background-color: nth(map-get($uc-tags-menu, "link-background-color"), 3);
        color: nth(map-get($uc-tags-menu, "link-color"), 3);
    }

}