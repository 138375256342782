//breadcrumbs as component
$breadcrumbs-margin-top:        17px !default;
$breadcrumbs-last-item-color:   #757575 !default;
$breadcrumbs-item-color:        $text-color-gray !default;
$breadcrumbs-item-color-hover:  $text-color !default;
$breadcrumbs-separator-color:   $text-color-gray !default;
$breadcrumbs-item-font-size: 1.2rem !default;
$enable-breadcrumbs-style: false !default;


.Breadcrumbs {
    margin-top: $breadcrumbs-margin-top;
    z-index: 0;

    ul {
        @extend %flex;
        @extend %flex-wrap;
        @extend %flex-item-center;
        margin-bottom: 0;
        padding-left: 0;

        > li {
            @extend %flex;
            @extend %flex-item-center;
            flex-direction: row-reverse;

            > a {
                display: inline-block;
                font-size: $breadcrumbs-item-font-size;
                text-decoration: none;

                @if ($enable-breadcrumbs-style == false) {
                    @extend %u-list-title-l-to-d;
                } @else {
                    color: $breadcrumbs-item-color;

                    &:hover {
                        color: $breadcrumbs-item-color-hover;
                    }
                }
                
                &:focus{
                    color: $text-color;
                    text-decoration: underline;
                }
            }
            &:last-child{
                .Seperator {
                    display: none;
                }
                &.PathItem > a,
                > a {
                    color: $breadcrumbs-last-item-color;
                    pointer-events: none;
                    cursor: auto;
                }
                &.ArchiveItem{
                    @media(max-width: $screen-xs-max) {
                        display: none;
                    }
                }
            }

            .Seperator {
                margin: 0 7px;
                color: $breadcrumbs-separator-color;
            }

            &:first-child {
                display: none; //hide item with "Du er her:" text
            }
        }
    }
}
