.uc173 {
    $_action-element-size: 46px; 

    display: inline;

    input, button{
        height: $_action-element-size;
        outline: 0 !important;
    }
    &__panel-body {
        @extend %card-default-style;
        @extend %card-padding-base;

        display: flex;
        flex-direction: column;

        @media (min-width: $screen-sm-min) {
            flex-direction: row;
            align-items: center;
        }
        > * {
            margin-bottom: 16px;
            @media (min-width: $screen-md-min) {
                margin-bottom: 0;
                &:not(:first-child) {
                    margin-left: 24px;
                }
            }
        }
    }
    &__search-input{
        font-size: 16px;
        &::placeholder{
            color: $text-color;
            font-size: 16px;
        }
    }
    &__quantity-container{
        display: flex;
        flex-direction: column;

        @media (min-width: $screen-sm-min) {
            flex-direction: row;
            align-items: center;
        }
    }
    &__quantity-label{
        margin-right: 10px;
        @media (min-width: $screen-sm-min) {
            margin-bottom: 0;
        }
    }
    &__quantity-input{
        text-align: center;
        min-width: 100px;
    }
    &__quantity-controls {
        display: flex;
        align-items: center;
        > * {
            &:not(:first-child){
                margin-left: 10px;
            }
        }
    }
    &__quantity-modify-btn{
        color: $text-color;
        border-color: $table-border-color;
        border-radius: 999px;
        padding: 0;
        width: $_action-element-size;
        flex-shrink: 0;

        &_decrease{
            &::before{
                @extend %fa-minus;
            }
        }
        &_increase{
            &::before {
                @extend %fa-plus;
            }
        }
    }
    .alert{
        margin-top: 10px;
        display: none;
        &.show {
            display: block;
        }
    }
}