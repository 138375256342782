.Price {
    font-weight: $txt-price-weight;
    color: $txt-price-color;
    white-space: nowrap;
}

/* WTG-188 */
.popup-after-buy__prod-item-price-original,
//.UC169 .OriginalPriceValue,
.UC170 .OriginalPrice,
.UC193 .OriginalPrice,
.quick-view-product__before-price-value,
.quick-view-compact-product__before-price-value {
    text-decoration: line-through;
    white-space: nowrap;
    color: $txt-original-price-color;
}