.wrp-slide-on-hover{
    .uc-image-list-v2 {
        &-item {
            $this: '.uc-image-list-v2-item';
            position: relative;
            overflow: hidden;

            &__image-container {
                @extend %flex;
                @extend %flex-center;
                @extend %flex-item-center;
            }

            &__img  {
                width: 100%;
            }

            &__content {
                @extend %flex;
                @extend %flex-column;
                @extend %flex-center;
                @extend %flex-item-center;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background-color: $wrp-slide-content-bg;
                height: 100%;
                padding: 0 8.7rem;
                transition: all .3s ease;

                @media (min-width: $screen-md-min) {
                    height: 6.4rem;
                }
            }

            &__text {
                font-size: 1.6rem;
                color: $cl_White;
                font-weight: 500;
                line-height: 3rem;

                @media (min-width: $screen-md-min) {
                    font-size: 2.4rem;
                }
            }

            &__description {
                display: none;
                font-size: 1.6rem;
                color: $cl_White;
                text-align: center;
                line-height: 2.4rem;
                margin: 1rem 0;
                max-width: 100%;

                @media (min-width: $screen-md-min) {
                    font-size: 1.6rem;
                    margin: 2rem 0 3rem;
                }
            }

            &__btn {
                @extend %u-btn-default;

                @media (min-width: $screen-md-min) {
                    display: none;
                    transition: all $animation-duration ease;
                }

                &:hover{
                    @extend %u-btn-primary;
                }
            }

            @media (min-width: $screen-md-min) {
                &:hover {
                    #{$this}__content {
                        height: 100%;
                    }

                    #{$this}__description {
                        display: block;
                    }

                    #{$this}__btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }
}