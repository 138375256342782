/*Doucument list control*/
.Paginator {
    display: table;
    margin: 0 auto;
}

.PagerContainer {
    margin-top: 30px; // a little bit bigger then bootstrap's ".pagination" - should be checked at uc193
    float: none;
    clear: both;
    position: relative;
    text-align: center;
    a,
    .SelectedPageNumber {
        display: inline-block;
        padding: 0 5px;
        margin: 0 2px;
        cursor: pointer;
        line-height: 18px;
        text-decoration: none;
    }
    span {
        line-height: 18px;
    }
    .PageNumber {
        min-width: 8px;
        padding: 0 5px;
        text-align: center;
    }
    .SelectedPageNumber,
    span.PageNumber {
        font-weight: bold;
    }
    .PagerPrevious,
    .PagerNext {
        @media (max-width: $screen-xs-max) {
            padding: 0 15px;
            position: absolute;
            bottom: 0px;
            border: 2px solid $cl_Gray_Nurse1;
            border-radius: 15px;
            line-height: 30px;
            font-weight: bold;
        }
    }
    .PagerPrevious {
        margin-right: 5px;
        left: 0;
    }
    .PagerNext {
        margin-left: 5px;
        right: 0;
    }
    .PagerCurrentPage {
        padding-right: 3px;
    }
    .PagerTotalPages {
        padding-left: 3px;
        padding-right: 2px;
    }
    .PagerVLine {
        padding: 0 3px;
        color: $cl_Mine_Shaft2;
    }

    @media (max-width: $screen-xs-max) {
        padding-bottom: 40px;
    }
}
/*_Pagination*/