.c-rating {
    display: flex;
    align-items: center;
    &__scale {
        display: flex;
        align-items: center;
    }
    &__star {
        color: $brand-primary;
        white-space: nowrap;
        &:not(:first-child) {
            margin-left: 0.11em;
        }
        @at-root button#{&} {
            @extend %u-reset-btn-to-a-block; //reset for uc205 rating control
        }
        &::before {
            @extend %fa-star;
        }
        &_half {
            &::before {
                @extend %fa-star-half-o;
            }
        }
        &_empty {
            &::before {
                @extend %fa-star-o;
            }
        }
    }
    &__comments {
        &-count {
            margin-left: 0.2em;
            color: $gray-light;
            &::before {
                content: "(";
            }
            &::after {
                content: ")";
            }
        }
    }
}