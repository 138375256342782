%bonus-system-before-flag{
    &::before{
        content: "%";
        color: #fff;
        background-color: #000;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 6px;
        font-weight: $label-font-weight;
        font-size: 16px;
        flex-shrink: 0;
    }
}
%bonus-system-pane{
    border: 1px solid #000;
    padding: $grid-size $grid-size * 2;
    display: flex;
    align-items: center;
}