#root {
    width: 100%;
    min-width: 320px;
    text-align: left;
    overflow-x: hidden;
}

.site{
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &-content {
        flex-grow: 1;
    }
}

.MiddleBox{
    @extend %u-list-unstyled;
}

td {
    vertical-align: top;
}