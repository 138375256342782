// Colors
$cl_Gallery1: $cl_Gallery3;
$cl_Wild_Sand: $cl_Wild_Sand1;

@import "_bootstrap-variables"; //template variables inject

/*==========================================
=            TEMPLATE VARIABLES            =
===========================================*/
$heading-h1-mb:             3.4rem;

// Override colors
$site-why-buy-text-color: $cl_White;
$site-why-buy-check-color: $cl_White;

$u-btn-primary-bg: $brand-primary;
$u-btn-primary-color: $text-color;
$u-btn-primary-border: transparent;
$u-btn-primary-border-hover: transparent;
$u-btn-primary-color-hover: $text-color;
$u-btn-primary-bg-hover: $brand-primary;
$u-btn-default-color: $text-color;
$u-btn-default-border: $text-color;
$u-btn-default-color-hover: $text-color;
$u-btn-default-bg: $cl_White;
$u-btn-default-bg-hover: $brand-primary;
$u-btn-default-border-hover: $brand-primary;

$site-footer-bg: #212529;
$site-footer-title-color: $cl_White;
$site-footer-link-color-hover: $cl_Celeste;
$uc210-footer-btn__style: "%u-btn-primary";
$uc210-footer-title__color: $cl_White;
$uc210-footer-placeholder__color: $cl_White;
$uc210-footer-input__color: $cl_White;
$uc210-footer-privacy-policy-link__color-hover: $cl_Celeste;

// Size Override
$site-why-buy-check-space: .8rem;
$site-why-buy-space_md: 2.4rem;
$uc210-footer__height: 2.7rem;
$uc210-footer-btn__padding: 0 1.5rem;
$wrp-slide-content-bg: rgba(#212529, .7);

/*----------  TYPOGRAPHY  ----------*/


$PM-line-height:            32px;
$PM-sublevel-line-height:   32px;


/* HEADER */
$site-header-desktop__bg:                       #212529;
$site-header-desktop__top-line-background:      #212529;
$site-header-desktop__top-line-color:           $cl_White;
$site-header-desktop__top-line-icon-color:      $brand-primary;
$site-header-desktop__top-line-height:          24px;
$site-header-desktop__middle-line-height:       80px;
$site-header-desktop__middle-line-stuck-height: 78px;
$site-header-desktop__bottom-line-background:   #212529;
$site-header-desktop__bottom-line-height:       $PM-line-height;
$site-header-desktop__btn-font-size:            30px;
$site-header-desktop__btn-color:                $cl_White;
$site-cart-popup-prod-count__color: $text-color;

$site-header-mobile__bg:                        $cl_White;
$site-header-mobile__height:                    56px;
$site-header-mobile__btn-space-around:          8px;
$site-header-mobile__btn-font-size:             22px;
$site-header-mobile__btn-color:                 $site-header-desktop__top-line-background;
$site-search__icon-color:                       $site-header-desktop__top-line-background;
$site-search__border:                           1px solid $site-header-desktop__top-line-background;

/*----------  CONTROLS OVERRIDE  ----------*/

    /*----------  SITE SEARCH  ----------*/
    $site-search__width:            45rem;

    /*----------  ADDTHIS  ----------*/
    $addthis__size: 3.2rem;
    $addthis__space: 1.8rem;
    $addthis__font-size: 1.6rem;
    $addthis__border-radius: 99rem;

    $addthis__color: $text-color;
    $addthis__border-width: .1rem;
    $addthis__border-color: $text-color;

    $addthis__bg: $cl_White;
    $addthis__bg-hover: $brand-primary;
    $addthis__color-hover: $text-color;
    $addthis__border-color-hover: $text-color;


    /*----------  SLICK CAROUSEL  ----------*/
    $slick-carousel__caption-bg:            rgba($cl_Deep_Cerulean, .8);
    $slick-carousel__caption-bg_hover:      rgba($cl_Deep_Cerulean, 1);
    $slick-carousel__caption-color:         $cl_White;

    $slick-carousel__title-color:           $slick-carousel__caption-color;

    //$slick-carousel__arrow-bg:              rgba($cl_3, 0.4);
    $slick-carousel__arrow-color:           $cl_White;

    $slick-carousel__arrow-bg_hover:        $brand-primary;
    $slick-carousel__arrow-color_hover:     $cl_Black;


/*----------  COMPONENTS OVERRIDE  ----------*/


    /*----------  WISH LIST  ----------*/
    $wish-list-btn__size:                   2.8rem;
    $wish-list-btn__font-size:              $font-size-base;
    $wish-list-btn__background-color:       rgba($cl_Tapa, .85) rgba($cl_Tapa, .85) rgba($cl_Tapa, .85) rgba($cl_Tapa, .85); // bg for Add, bg for Add on hover, bg for Remove, bg for Remove on hover
    $wish-list-btn__color:                  $cl_White $cl_White $cl_White $cl_White; // color for Add, color for Add on hover, color for Remove, color for Remove on hover

    $wish-list-btn-lg__size:                4rem;
    $wish-list-btn-lg__font-size:           2rem;


    /*----------  ADD TO CART  ----------*/
    $product-in-cart__color:                       $cl_Black;
    $product-in-cart-count__color:                 $cl_Black;
    $product-in-cart-count__background-color:      $brand-primary;


    /*----------  FLAGS  ----------*/
    $flag-theme-colors: (
        default: (
            colors: (
                background-color: $cl_Gallery1,
                color: $cl_Black,
            ),
        ),
        discount-percent: (
            colors: (
                background-color: $cl_Coral_Red,
                color: $cl_White,
            ),
        ),
        new: (
            id: 1,
            colors: (
                background-color: $cl_Gallery1,
                color: $cl_Black,
            ),
        ),
        discount: (
            id: 2,
            colors: (
                background-color: $cl_Gallery1,
                color: $cl_Coral_Red,
            ),
        ),
    );

/*----------  PARTS OVERRIDE  ----------*/

/*=====  End of TEMPLATE VARIABLES  ======*/
