/*======================================
=            Shopping Lists            =
======================================*/
@import "uc201-common";

.uc201-ff{
    @import "uc201-xx-common";
    /*----------  SHOPPING LIST DETAILS VIEW  ----------*/
    &-shopping-list-details{
        .alert{
            margin-top: 10px;
            margin-bottom: 0;
        }

        &__grid-cell-content{
            &_quantity{
                display: flex;
                flex-direction: column;
                @media (min-width: $screen-sm-min) {
                    padding: $grid-size 0;
                }
                > *:not(:first-child)
                {
                    margin-top: $grid-size * 2;
                }
                label{
                    font-weight: 400;
                }
            }
            &_action-buttons{
                display: flex;
                justify-content: space-between;
                @media (min-width: $screen-sm-min) {
                    justify-content: center;
                }
                & > button:not(:first-child) {
                    margin-left: $grid-size;
                }
            }
        }
        &__quantity-panel{
            display: flex;
            justify-content: space-between;
            > *{
                width: 50%;
            }
        }
        &__quantity-regular,
        &__fraction{
            max-width: 60px;
        }
        &__factor{
            max-width: 130px;
            &-label{
                white-space: nowrap;
            }
            &_align-right{
                margin-left: auto;
            }
        }
        &__total-quantity{
            display: flex;
            margin-bottom: 0;
            padding: $grid-size 11px;
            border: 1px solid $table-border-color;
            background-color: #EEEEEE;
            dt{
                &::after{
                    content: ":";
                }
            }
            dd{
                margin-left: $grid-size * 2;
            }
        }
        &__keyboard-navication-link{
            display: inline-block;
            &:hover,
            &:focus{
                i{
                    text-decoration: none;
                }
            }
        }
    }
}
/*=====  End of Shopping Lists  ======*/