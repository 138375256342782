/* Jumps and custom width */
@media (min-width: $screen-sm-min) {
    .pull-sm-left {
        float: left;
    }
    .pull-sm-right {
        float: right;
    }
}
@media (min-width: $screen-md-min) {
    .pull-md-left {
        float: left;
    }
    .pull-md-right {
        float: right;
    }
}
@media (min-width: $screen-lg-min) {
    .pull-lg-left {
        float: left;
    }
    .pull-lg-right {
        float: right;
    }
}

//Extra space for elements like at bootstrap 4 approach
@for $i from 1 through 10 {
    .mb-#{$i} { margin-bottom: 10px * $i; }
}
@media (min-width: $screen-sm-min) {
    @for $i from 1 through 10 {
        .mb-sm-#{$i} { margin-bottom: 10px * $i; }
    }
}
@media (max-width: $screen-xs-max) {
    @for $i from 1 through 10 {
        .mb-xs-#{$i} { margin-bottom: 10px * $i; }
    }
}

/*ImageWithRelated*/
@media (min-width: $screen-lg-min) {
    @for $i from 1 through 10 {
        .d-im#{$i} .ImageWithRelated { width: 10% * $i; }
    }
}