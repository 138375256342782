$enable-btn-height-small: false !default;

%u-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0; // For input.btn
    font-weight: $btn-font-weight;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;
    white-space: nowrap;
    @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $btn-border-radius-base);
    @include user-select(none);

    &,
    &:active {
        &:focus {
            @include tab-focus;
        }
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:active {
        outline: 0;
        @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
    }

    &[disabled]{
        cursor: $cursor-disabled;
        @include opacity(.65);
        @include box-shadow(none);
    }

    // [converter] extracted a& to a.btn
}

%u-btn-default {
    text-decoration: none;
    text-transform: $u-btn-text-transform;
    //@include uni-button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
    @include uni-button-advanced-hover($u-btn-default-color, $u-btn-default-bg, $u-btn-default-border, $u-btn-default-color-hover, $u-btn-default-bg-hover, $u-btn-default-border-hover);
}
%u-btn-primary {
    text-decoration: none;
    text-transform: $u-btn-text-transform;
    //@include uni-button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
    @include uni-button-advanced-hover($u-btn-primary-color, $u-btn-primary-bg, $u-btn-primary-border, $u-btn-primary-color-hover, $u-btn-primary-bg-hover, $u-btn-primary-border-hover);
}
// Success appears as green
%u-btn-success {
    @include uni-button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}
// Info appears as blue-green
%u-btn-info {
    @include uni-button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}
// Warning appears as orange
%u-btn-warning {
    @include uni-button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}
// Danger and error appear as red
%u-btn-danger {
    @include uni-button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}
//-----------------------------------------------------------------------------------------------------
%u-reset-btn-to-a-block {
    background: none;
    padding: 0;
    border: 0;
}
%u-reset-btn-to-a-link {
    background: none;
    color: $link-color;
    text-transform: initial;
    &:focus,
    &:hover {
        color: $link-color;
        box-shadow: none;
    }
}
%u-reset-btn-state{
    &:focus {
        outline: none;
        &:active {
            outline: none;
        }
    }
    &:active {
        box-shadow: none;
    }
}
%u-btn-flex-it{
    // used only for labels or links which should be shown as buttons
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
%u-btn-size-small{
    @if($enable-btn-height-small == true) {
        height: $u-buy-btn-height-small;
        font-size: $u-buy-btn-font-size-small;

        @media (min-width: $screen-md-min) {
            height: $u-buy-btn-height-base;
            font-size: $u-buy-btn-font-size-base;
        }
    } @else {
        height: $u-buy-btn-height-base;
        font-size: $u-buy-btn-font-size-base;
    }
    
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
}

%u-btn-size-large {
    min-width: $u-buy-btn-height-large;
    height: $u-buy-btn-height-large;
    font-size: $font-size-large;
}

%u-btn-icon-animation {
    &::before{
        display: inline-block;
        overflow: hidden;
        width: 0;
        height: 0;
        opacity: 0;
        transition: width $animation-duration, opacity $animation-duration;
    }
    &:focus{
        outline: none;
    }
    &:focus,
    &:hover{
        &::before{
            width: 30px;
            opacity: 1;
            height: auto;
        }
    }
}