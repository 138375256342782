.SearchDesktop {
    width: $site-search__width;
    height: $site-search__button-height;

    &__input {
        width: 100%;
        height: $site-search__button-height;
        padding: $site-search__input-padding;
        font-size: $site-search__input-xs-font-size;
        color: $site-search__input-color;
        border: $site-search__border;

        @media(min-width: $screen-sm-min){
            font-size: $site-search__input-font-size;
        }

        &::placeholder{
            color: $site-search__input-color;
        }
    }

    &__btn {
        width: $site-search__button-width;
        height: $site-search__button-height;
        outline: none;
        &::after {
            color: $site-search__icon-color;
            font-size: $site-search__icon-font-size;
        }
    }

}