@function u-is-light-color ($color, $threshold: 50%) {
    @if type-of($color) != color {
        @error "$color parameter should be type of 'color'";
    }
    @if type-of($threshold) != number and unit($threshold) == "%" {
        @error "$threshold parameter should be type of 'number' and have percentage unit";
    }
    @if lightness($color) > $threshold {
        @return true;
    } @else {
        @return false;
    }
}

@function u-is-dark-color ($color, $threshold: 50%) {
    @return not u-is-light-color($color, $threshold);
}

@function u-get-adaptive-color ($background-color, $light-text-color: $cl_White, $dark-text-color: $text-color, $threshold: 50%) {
    @if type-of($background-color) != color or type-of($light-text-color) != color or type-of($dark-text-color) != color {
        @error "$background-color, $light-text-color and $dark-text-color parameters should be type of 'color'";
    }
    @if u-is-light-color($background-color, $threshold) {
        @return $dark-text-color;
    } @else {
        @return $light-text-color;
    }
}

@mixin u-set-adaptive-color ($background-color, $light-text-color: $cl_White, $dark-text-color: $text-color, $threshold: 50%) {
    color: u-get-adaptive-color($background-color, $light-text-color, $dark-text-color, $threshold);
}