.uc208{
    text-align: center;
    &-content{
        & > p:not(:first-child){
            margin-top: 1.1em;
        }
    }
    &-heading{
        margin-bottom: 1.5em;
    }
    &-panel-body{
        padding-top: 4em;
        padding-bottom: 4em;
    }
    &-resubscribe{
        padding-top: 2em;
        border-top: 1px solid $table-border-color;
        display: inline-block;
    }
    // &-manage-preferences , could appear in some time : ) between uc208-unsubscribe and uc208-resubscribe containers
}