.UC173 {
    .MiddleBox {
        padding: 10px 10px 0;
        border: 2px solid $cl_Celeste;

        @media (min-width: $screen-sm-min) {
            float: left;
        }
    }
    ul {
        padding-left: 0;
        margin-bottom: 0;
    }
    li {
        display: block;
        margin-bottom: 10px;
        float: left;
        &:first-child {
            margin-left: 0;
            margin-right: 10px;

            @media (max-width: $screen-xs-max) {
                width: calc(100% - 55px);
            }
        }
        &:last-child {
            float: none;
            clear: both;

            @media (min-width: $screen-md-min) {
                float: left;
                clear: none;
            }
        }

        @media (min-width: $screen-md-min) {
            margin-left: 10px;
        }
    }
    .ProductNumberTxt,
    .QuantityTxt,
    .ErrMsg {
        line-height: 22px;
    }
    .ProductNumberTxt,
    .QuantityTxt {
        display: block;

        @media (min-width: $screen-sm-min) {
            display: inline-block;
            padding-right: 10px;
        }
    }
    .FormBox,
    .QuantityTxb,
    .FormButton {
        display: inline-block;
        height: 45px;
        &:focus {
            outline: 2px solid $cl_Red;
        }
    }
    .FormBox {
        width: auto;
        @media (max-width: $screen-xs-max) {
            width: 100%;
        }
    }
    .FormButton {
        width: 100%;

        @media (min-width: $screen-md-min) {
            width: auto;
        }
    }
    .QuantityTxb {
        width: 45px;
        height: 45px;
        text-align: center;
    }
    .ErrMsg {
        display: none;
        &.show {
            display: block;
            padding: 4px;
            margin-bottom: 10px;
            border: 1px solid $cl_Celeste;
            border-radius: 2px;
            background-color: $cl_White;
        }
    }
}

/*_UC173 Quick buy*/