.uc129 {
    .FormLabel,
    .RequiredFieldCont {
        display: none;
    }

    &__image-code-cont {
        @extend %flex;
        @extend %flex-wrap;
        @extend %flex-center;
        margin-bottom: 1.2rem;

        .FormBox {
            height: 3.2rem;
            width: 11.5rem;
        }
    }

    &__contact-info-cont {
        .FormBox,
        .FormDrpBox {
            height: 4rem;
            width: 100%;
            margin-bottom: .8rem;
        }
    }

    &__form-multi-box {
        height: 10rem;
        resize: none;
        margin-bottom: 1.2rem;
    }

    &__form-button {
        @extend %u-btn-primary;
        @extend %u-btn-size-large;
        width: 100%;
    }
}
