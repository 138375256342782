/*UC174 List Child Pages WCAG edition. All item content in the link. */
.uc174-wcag {
    .ListContainer{
        display: flex;
        flex-wrap: wrap;
    }
    .Item{
        @extend %card-default-style;
        display: flex;
        flex-direction: row;
        align-items: center;
        @media (min-width: $screen-sm-min) {
            flex-direction: column;
            padding-bottom: 2rem;
        }
        &:hover,
        &:focus{
            text-decoration: none;
            .ItemTitle{
                text-decoration: underline;
            }
        }
        @extend %u-focus-outline-bordered;
    }
    .ImageContainer{
        width: 7rem;
        flex-shrink: 0;
        @media (min-width: $screen-sm-min) {
            width: 100%;
        }
        img {
            width: 100%;
        }
    }
    .ItemTitle{
        font-size: $font-size-large;
        font-weight: $label-font-weight;
        @extend %u-list-title-d-to-l;
        padding: 0 1em;
        margin-top: 0;
        margin-bottom: 0;
        @media (min-width: $screen-sm-min) {
            margin-top: 2.4rem;
            margin-bottom: 2.4rem;
            text-align: center;
        }
    }
    //Most likely the button will not be shown. Trend of latest designs. 
    button{
        margin-top: auto;
        text-decoration: none;
        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }
}