.additional-products {

    .grid {
        margin-bottom: 22px;
        @media (min-width: $screen-sm-min) {
            @include make-grid(10px $grid-size * 2);
            .grid-header {
                background-color: #F7F7F7;
            }
            .grid-body {
                .grid-row {
                    border-bottom: 1px solid #ddd;
                }
            }
        }
        .grid-cell {
            vertical-align: middle;
        }
        @media (max-width: $screen-xs-max) {
            text-align: center;
            border-bottom: 1px solid #ddd;
            @include make-mobile-grid (1px solid #ddd, $padding-large-vertical, $padding-small-vertical $padding-large-horizontal);
            .additional-product__td-details{
                align-items: center;
                display: flex;
                flex-direction: column;
            }
        }
        &-header {
            font-size: 1.4rem;
            font-weight: 500;
        }
    }

    &__total-and-buy{
        @media (min-width: $screen-sm-min) {
            width: 275px;
            margin-left: auto;
            margin-right: 0;
        }
    }
    &-total-cont {
        margin-top: 20px;

        @media (min-width: $screen-sm-min) {
            width: 275px;
            margin-left: auto;
            margin-right: 0;
        }

        &__row {
            display: flex;
            align-items: baseline;
            white-space: nowrap;
            &:not(:first-child) {
                margin-top: .4rem;
            }
        }
        &__key {
            flex: 0 0;
            margin-right: .6rem;
        }
        &__total-sum,
        &__key {
            font-weight: 500;
        }
    }

    .BuyButtonContainer {
        margin-top: 1.6rem;

        @media (min-width: $screen-sm-min) {
            width: 275px;
            margin-left: auto;
            margin-right: 0;
        }
    }

    &__buy-button-container{
        margin-top: 20px;
    }
    .c-add-to-cart-btn {
        width: 100%;
        @extend %u-btn-size-large;
    }
}
.additional-product {
    %input-styles {
        height: 28px;
        padding: 0 .7rem;
        border-color: transparent;
        box-shadow: none;
        background-color: #eee;
    }

    &__heading,
    &__category-heading {
        display: none;
    }

    &__title {
        font-size: 1.4rem;
        font-weight: 500;
        color: $text-color;

        &-link {
            font-size: 1.4rem;
            font-weight: 500;
            color: $text-color;
        }
    }

    &__number {
        display: none;
    }

    .uc-product-view__product-variants{
        margin-top: 0;
    }

    &__stock {
        margin-top: $grid-size;
        display: flex;
        &-term-definition{
            margin-right: $grid-size;
            font-weight: 400;
        }
    }

    &__dimensions {
        margin-top: $grid-size;
    }

    &__price {
        font-weight: 500;
    }

    &__td {
        &-image {
            @media (min-width: $screen-sm-min) {
                width: 1%;
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }

        &-details,
        &-custom-fields {
            @media (min-width: $screen-sm-min) {
                width: 50%;
            }
        }

        &-price {
            @media (min-width: $screen-sm-min) {
                width: 1%;
                white-space: nowrap;
                padding-top: $grid-gutter-width * 0.5 + 10px;
            }
        }

        &-quantity {
            @media (min-width: $screen-sm-min) {
                width: 1%;
                white-space: nowrap;
                padding-top: $grid-gutter-width * 0.5 + 4px;
                padding-right: 0 !important;
            }
        }
    }

    &__product-editable-custom-fields {
        display: table;
        width: 100%;
        max-width: 300px;
        margin: auto;
    }

    &__product-custom-field {
        display: table-row;
        &-term-definition,
        &-term-description {
            display: table-cell;
            vertical-align: middle;
        }
        & + & &-term-definition,
        & + & &-term-description {
            padding-top: .8rem;
        }
        &-term-definition {
            white-space: nowrap;
            padding-right: .6rem;
        }
        &-label {
            font-weight: 400;
            margin-bottom: 0;
        }
        input[type="text"] {
            @extend %input-styles;
            &::placeholder {
                color: transparent;
            }
        }
    }
}