$_this: &;
$_btn-icon-space: 0.4em;

&__product-container{
    display: flex;
    flex-direction: column;
    @media (min-width: $screen-md-min) {
        flex-direction: row;
        align-items: flex-start;
    }
}
&__product-images{
    margin-bottom: $grid-gutter-width;
    display: flex;
    flex-direction: column;
    @media (min-width: $screen-md-min) {
        flex-direction: row-reverse;
        margin-bottom: 0;
        align-items: flex-start;
    }
}
&__product-image-link{
    @extend %u-focus-outline-bordered;
}
//Product related images unordered list
&__product-thumbnails{
    @media (min-width: $screen-md-min) {
        flex: 0 0;
        display: flex;
        flex-wrap: no-wrap;
        flex-direction: column;
    }
    &-list{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;
        margin-bottom: 0; // reset default margin-bottom
        @media (min-width: $screen-md-min) {
            flex-wrap: no-wrap;
            flex-direction: column;
            margin-top: 0;
        }
    }
    &-item{
        display: flex;
        flex-direction: column;
        @media (min-width: $screen-md-min) {
            &:not(:first-child){
                margin-top: $grid-size;
            }
        }
        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            @include row-machine(7, $grid-size);
        }
        @media (max-width: $screen-xs-max) {
            @include row-machine(6, $grid-size);
        }
    }
    &-image-container{
        @extend %card-default-style;
    }
    &-see-more{
        margin-top: $grid-size;
        @media (max-width: $screen-sm-max) {
            display: none;
        }
    }
}
//Product's main image block with absolute positioned elements on it
&__product-image-container{
    @extend %card-default-style;
    position: relative;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    min-height: floor(($_default-image-size * 0.5));
    @media (min-width: $screen-md-min) {
        min-height: $_default-image-size;
    }
    &-img{
        max-height: floor(($_default-image-size * 2));
    }
}

//Product sidebar children items
&__product-name{
    margin-bottom: 0;
}

&__product-landing-description{
    margin-top: 18px;
    display: flex;
    flex-direction: column;

    &-section-1{
        display: flex;
        flex-direction: column;
        padding-bottom: 6px;
        border-bottom: 1px solid $table-border-color;
        margin-bottom: 6px;
    }
    &-section-2{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        border-bottom: 1px solid $table-border-color;
        padding-bottom: 5px;
    }
}

//Landing section 1
&__product-stock{
    display: flex;
    margin-bottom: 0;

    &-term-definition{
        font-weight: normal;
        margin-right: 5px;

        &::after{
            content: ":";
        }
    }

    &-term-description{
        font-weight: $label-font-weight;
    }
}
&__product-rating{
    align-items: flex-end;
    display: flex;
    flex-direction: column;

    &-scale{
        font-size: 11px;
        font-weight: $headings-font-weight;
    }
    &-comments-count{
        font-size: 12px;
        font-weight: $headings-font-weight;
    }
    &-add-review-btn{
        text-decoration: underline;
        font-size: 12px;
        font-weight: $headings-font-weight;
    }
}
&__product-prices{
    margin-bottom: 0;
}
&__product-editable-custom-fields{
    margin-top: $grid-size * 3;
    padding-top: $grid-size * 3;
    border-top: 1px solid $table-border-color;
}
&__product-quantity-discounts{
    &-btn {
        display: inline-block;
        padding: 0;
        text-decoration: underline;
        font-weight: $headings-font-weight;
        &::after {
            display: inline-block;
            @extend %fa-info-circle;
            margin-left: 4px;
            color: $cl_Pale_Sky;
            text-decoration: none;
        }
    }
    &-drop-down{
        left: auto;
        right: 0;
        .QuantityDiscounts{
            padding: 0 10px;
        }
        .QuantityDiscount{
            white-space: nowrap;
        }
    }
}
&__product-variants{
    display: flex;
    flex-direction: column;
}
&__product-add-to-cart{  //.c-add-to-cart
    margin-top: 8px;
}
&__product-package-buy{
    &-description{
        margin-bottom: 8px;
    }
}
&__product-price-request-btn{
    @extend %u-btn-icon-animation;
    &::before{
        @extend %fa-envelope;
    }
}
&__product-add-to-shopping-list-btn{
    @extend %u-btn-icon-animation;
    &::before{
        @extend %fa-document;
    }
}
&__product-information{
    margin-bottom: 0;
    & > div{
        display: flex;
        flex-wrap: wrap;
        &:not(:last-child){
            margin-bottom: 2px;
        }
        & > dt{
            margin-right: 0.4em;
            &::after{
                content: ":";
            }
        }
    }
    &_landing{
        & > div{
            margin-bottom: 0;
        }
        dt{
            font-weight: 400;
        }
        dd{
            margin-bottom: 0;
        }
    }
}
&__product-teaser + &__product-content{
    margin-top: $grid-size * 2;
}
&__product-teaser,
&__product-content{
    img.LocalResource{
        max-width: 100%;
    }
}

&__product-teaser {
    font-weight: 700;
}

&__product-tab-pane-description-buttons{
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}
&__product-download-pdf-btn{
    &::before{
        @extend %fa-file-o;
        margin-right: $_btn-icon-space;
    }
}