/*Common element showing on which payment step site user is.
Used at following controls: UC110 & UC140/147 */

.c-checkout-step-indicator {
    width: 288px;
    height: 60px;
    background-image: url("../img/checkout/checkout-progress-sprite-sm.png");
    background-repeat: no-repeat;

    &_step-1 {
        display: none; // backward compatibility
    }
    &_step-2 { // real fist step!
        background-position: 0 0;
    }
    &_step-3 {
        background-position: 0 -55px;
    }
    &_step-4 {
        background-position: 0 -110px;
    }
    &_step-5{
        background-position: 0 -165px;
    }
}