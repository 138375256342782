$min-side-icon__size: 31px !default;

.profile-quick-menu {
    position: relative;

    &__btn {
        display: block;
        text-decoration: none;

        span {
            display: none;
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &__un-user {
        display: flex; //remove white space around the icon
        &::before {
            @extend %un-user-slim;
            font-size: $min-side-icon__size;
        }
    }
    &__un-chevron-down{
        display: none;
    }
}