.UC176 {
    .FormBox {
        width: 100px;
        padding: 0 5px;
        border: 1px solid $cl_White;
        background-color: transparent;
        box-sizing: border-box;
        color: $cl_White !important;

        @media (max-width: $screen-xs-max) {
            width: 70px;
        }
    }
    .FormButton {
        text-transform: uppercase;

        @media (max-width: $screen-xs-max) {
            width: auto;
            height: 38px;
            padding: 0 5px;
            line-height: 38px;
            font-size: 13px;
            font-weight: bold;
        }
    }
    .ValueCheckControls {
        display: inline-block;
        padding: 5px 20px;
        margin: 25px 0;
        background: $brand-primary;
        line-height: 40px;
        color: $cl_White;
        * {
            display: inline-block;
            vertical-align: middle;
        }
    }

    /*
     div.ErrorMsg, div.Invalid, div.SuccessMsg{
        color: #ABD573!important;
     }
    */
}
/*
 ????????
 .Undeliverable{
     opacity: .2;
 }
 .Invalid, .valid{
     background: #ABD573!important;
     color: $cl_White!important;
 }
*/