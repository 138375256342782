.c-price{
    $_this: &;
    display: flex;
    align-items: baseline;
    &__term-definition{
        white-space: nowrap;
        font-weight: normal;
        margin-right: 4px;
        display: flex;
        &::after{
            content: ":";
        }
        & > *:not(:first-child){
            margin-left: 4px;
        }
    }

    //&__from-text{}
    &__vat{
        white-space: nowrap;
    }
    &__value{
        white-space: nowrap;
    }
    &_before-price{
        #{$_this}__value{
            color: $gray-light;
        }
    }
    //&_discount-price{
        //margin-top: 1em;
    //}
    &_sell-price,
    &_price-2,
    &_price-3{
        #{$_this}__value{
            font-size: $font-size-h1;
            font-weight: $headings-font-weight;
        }
    }
}