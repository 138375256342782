@mixin scrollbar{
    &::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar
    {
        width: 8px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb
    {
        border-radius: 6px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: $gray-light;
    }
}