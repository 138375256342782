/* Control: Views/User/Resellers.cshtml */
.UC178 {
    .RegionFilter {
        padding-left: 0;
        margin-bottom: 0;
        font-size: 0;
        li {
            display: inline-block;
            margin: 0 $table-cell-padding $table-cell-padding 0;
            @media (max-width: $screen-xs-max) {
                width: 49%;
                float: left;
                margin-right: 0;
                &:nth-child(2n + 1) {
                    margin-right: 2%;
                }
            }
            &.selected {
                a {
                    @extend %u-btn-primary;
                }
            }
        }
        a {
            display: block;
            @extend %u-btn;
            @extend %u-btn-default;
        }
    }
    table {
        width: 100%;
        margin-top: 30px;
        background-color: $cl_White;
    }
    thead {
        background-color: $text-color;
        color: $cl_White;
        td {
            padding: 0 $table-cell-padding;
            font-size: 1.1em;
        }
    }
    tr {
        height: 35px;
        &:nth-child(2n) {
            background-color: $table-bg-accent;
        }
    }
    td {
        padding: 3px $table-cell-padding;
        vertical-align: middle;
    }
}