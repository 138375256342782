.umw-bonus-program-info {
    &__heading{
        @extend %u-heading-h2;
        margin-bottom: $grid-size;
    }
    &__scenario-list{
        @extend %card-default-style;
        @extend %card-padding-base;
        background-color: $bonus-success-color;
    }
    &__scenario {
        background-color: #fff;
        border: 1px solid #000;
        @extend %bonus-system-pane;
        @extend %bonus-system-before-flag;
        &:not(:first-child){
            margin-top: $grid-size;
        }
    }
    &__link-container{
        @extend %bonus-system-before-flag;
    }
    &__info-link{
        text-decoration: underline;
        font-weight: $label-font-weight;
        color: $text-color;
    }
}