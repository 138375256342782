$site-footer-padding-top: 4.8rem !default;
$site-footer-padding-bottom: 2.4rem !default;

$site-footer-bg: $cl_Log_Cabin !default;
$site-footer-title-size: 1.4rem !default;
$site-footer-title-color: $cl_White !default;
$site-footer-title-weight: 700 !default;
$site-footer-title-space-bottom: 1.7rem !default;
$site-footer-title-transform: uppercase !default;

$site-footer-paragraph-size: 1.4rem !default;
$site-footer-paragraph-color: $cl_White !default;
$site-footer-paragraph-line-height: 2.2rem !default;
$site-footer-paragraph-space-bottom: 3rem !default;

$site-footer-link-color:  $cl_White !default;
$site-footer-link-color-hover: $gray-light !default;

$site-footer-menu-link-color: $site-footer-link-color !default;
$site-footer-menu-link-color-hover: $site-footer-link-color-hover !default;
$site-footer-menu-link-selected-color: $site-footer-menu-link-color !default;
$site-footer-menu-link-size: 1.4rem !default;

$site-footer-addthis-space-top: 6.4rem !default;
$site-footer-payment-info-space-top: 2.6rem !default;

$site-footer-privacy-policy-size: 1.2rem !default;
$site-footer-privacy-policy-color: $site-footer-menu-link-color !default;
$site-footer-privacy-policy-link-fw: 400 !default;

$site-footer-copyright-space-top: 4.4rem !default;
$site-footer-copyright-size: 1.3rem !default;
$site-footer-copyright-color: $cl_Star_Dust2 !default;
$site-footer-copyright-color-hover: $site-footer-link-color-hover !default;

@import "site-footer__payment-images";

.site-footer {
    background-color: $site-footer-bg;
    padding-top: $site-footer-padding-top;
    padding-bottom: $site-footer-padding-bottom;
    .mb-md-4{
        margin-bottom: 4rem;
        @media (min-width: $screen-md-min) {
            margin-bottom: 0;
        }
    }

    &__menu {
        ul {
            > .Page {
                line-height: 3rem;

                > .Name {
                    line-height: 1.5;
                    color: $site-footer-menu-link-color;
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    font-size: $site-footer-menu-link-size;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                        color: $site-footer-link-color-hover;
                    }
                }

                > ul {
                    display: none;
                }

                &.Selected {
                    > .Name {
                        color: $site-footer-menu-link-selected-color;
                        text-decoration: underline;
                        font-weight: $label-font-weight;
                        &:hover,
                        &:focus {
                            color: $site-footer-link-color-hover;
                        }
                    }
                }
            }
        }
    }

    &__doc {
        a {
            text-decoration: underline;
            &:hover,
            &:focus {
                color: $site-footer-link-color-hover;
            }
        }
    }

    a {
        color: $cl_White;
    }

    .MenuTitle,
    .MainTitle,
    &__title {
        display: block;
        font-size: $site-footer-title-size;
        color: $site-footer-title-color;
        font-weight: $site-footer-title-weight;
        margin-top: 0;
        margin-bottom: $site-footer-title-space-bottom;
        text-transform: $site-footer-title-transform;
        line-height: 1.1;
    }

    p {
        color: $site-footer-paragraph-color;
        font-size: $site-footer-paragraph-size;
        line-height: $site-footer-paragraph-line-height;
        margin-bottom: $site-footer-paragraph-space-bottom;

        strong {
            font-weight: 500;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .newsletter {
        &__controls {
            width: 100%;
            @media (min-width: $screen-md-min) {
                max-width: 33.3rem;
            }
        }
        &-privacy-policy__link{
            &:hover,
            &:focus{
                color: $site-footer-link-color-hover;
            }
        }
    }
}

div.addthis_toolbox {
    &.custom {
        margin-top: $site-footer-addthis-space-top;
    }
}

.copyright {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: $site-footer-copyright-space-top;
    text-align: center;

    @media (min-width: $screen-sm-min) {
        flex-direction: row;
    }

    &__by {
        margin-top: 1rem;

        @media (min-width: $screen-sm-min) {
            margin-left: 2rem;
            margin-top: 0;
        }
    }

    span,
    a {
        color: $site-footer-copyright-color;
        font-size: $site-footer-copyright-size;
    }

    a {
        text-decoration: underline;
        font-weight: 500;
        
        &:hover,
        &:focus {
            color: $site-footer-copyright-color-hover;
        }
        &:focus {
            text-decoration: underline;
        }
    }

}