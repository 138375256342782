$grid-size: 8px;

/* Items grid*/
$items-xs-gutters:          1rem 2% 1% !default;
$items-sm-gutters:          1rem 2.65% 2.65% 2.65% 2.65% 2.65% 2.65% !default;
$items-md-gutters:          1rem 2.65% 2.65% 2.65% 2.65% 2.65% 2.65% !default;
$items-lg-gutters:          1rem 2.65% 2.65% 2.65% 2.65% 1.5% 1.5% !default;

$l-aside-width-sm-v1: 220px !default;
$l-aside-width-md-v1: 220px !default;
$l-aside-width-lg-v1: 220px !default;

$l-aside-space-sm-v1: 35px !default;
$l-aside-space-md-v1: 35px !default;
$l-aside-space-lg-v1: 35px !default;

/*Layouts*/
@import "m-row-machine";
@import "base";
@import "items-grid";
@import "layout-helpers";
@import "layout-flex-helpers";
@import "layout-page-layout";
@import "e-flex";

/*Pages*/
@import "specific-pages/page-all";
@import "specific-pages/page-doc-view";
@import "specific-pages/page-user-profile";
@import "specific-pages/page-checkout";