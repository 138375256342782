.smart-search {
    .tt-menu{
        border: none;
    }
    &-suggestion {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: stretch;
        overflow: hidden;
        padding: 8px;
        background-color: $cl_White;

        &.tt-cursor{
            background-color: lighten($brand-primary, 40%);
        }
        &:not(:first-of-type) {
            border-top: 1px solid $cl_Alto2;
        }
        &:not(.tt-cursor):hover {
            cursor: pointer;
            background-color: $cl_Wild_Sand;
        }

        &_in-cart {
            background-color: $cl_Peppermint; // light green color used by default
            //background-color: lighten($brand-primary, 50%);
        }

        &_view-more {
            padding: 15px;
            justify-content: center;
            font-size: 12px;
            text-align: center;
            @media (min-width: $screen-sm-min) {
                padding: 3px;
            }
            a {
                text-decoration: underline;
                color: $text-color;
            }
        }


        /* image column */
        &__image-container {
            position: relative; // for the cart icon inside this container
            flex: 0 0 44px;
        }

        &__image {
            width: 100%;
        }
        &__fa-shopping-cart {
            display: none;
            /* style from the design, but we agreed that by default we dont need to show users this info
            position: absolute;
            bottom: 0;
            right: 0;
            color: $product-in-cart__color;
            &::before {
                @extend %u-standalone-cart-icon;
            }
            */
        }


        /* details column */
        &__details-container {
            margin-left: 9px;
            flex: 1 1;
        }

        &__title {
            overflow: hidden;
            max-height: $line-height-computed * 2;
        }
        &__minimal-quantity {
            display: none;
            /* style from the design, but we agreed that by default we dont need to show users this info
            font-size: 12px;
            font-weight: 300;
            */
        }


        /* price column */
        &__original-price-container,
        &__price-container {
            flex: 0 0;
            margin-left: 13px;
            text-align: right;
        }

        &__original-price,
        &__price {
            font-weight: 500;
            white-space: nowrap;
        }
        &__original-price-vat-text,
        &__vat-text {
            display: none;
        }


        /* buttons column */
        &__buttons-container {
            flex: 0 0;
            margin-left: 12px;
            @media (min-width: $screen-sm-min) {
                margin-left: 8px;
            }
        }

        &__read-more-btn,
        &__buy-btn {
            padding: 0;
            font-size: 12px;
        }

        &__quantity {
            &-container {
                display: none;
            }
            &-decrease,
            &-increase {
                width: 17px;
                line-height: 17px;
                font-size: 8px;
                @extend %u-reset-btn-to-a-link;
            }
            &-decrease {
                &::before {
                    @extend %fa-minus;
                }
            }
            &-increase {
                &::before {
                    @extend %fa-plus;
                }
            }
            &-input {
                width: 14px;
                height: 17px;
                margin-left: 1px;
                margin-right: 1px;
                padding: 0;
                border: 0;
                box-shadow: none;
                background: none;
                font-size: 12px;
                font-weight: 500;
                text-align: center;
            }
        }
    }

    &-empty-message {
        padding: 15px;
        text-align: center;
    }

}