@each $flag-theme-name, $flag-theme-data in $flag-theme-colors {
    %u-flag-theme-#{$flag-theme-name} {
        @each $flag-theme-property-name, $flag-theme-property-value in map-get($flag-theme-data, "colors") {
            #{$flag-theme-property-name}: $flag-theme-property-value;
        }
    }
}

%u-flag {
    z-index: z-index(flag);
    @include u-flag(
        $flag__type,
        $flag__position,
        $flag__size,
        $flag__padding,
        $flag__font
    );
}

%u-flag-lg {
    z-index: z-index(flag);
    @include u-flag(
        $flag-lg__type,
        $flag-lg__position,
        $flag-lg__size,
        $flag-lg__padding,
        $flag-lg__font
    );
}

%u-flag-discount-percent {
    z-index: z-index(flag);
    @extend %u-flag-theme-discount-percent;
    @include u-flag(
        $flag-discount-percent__type,
        $flag-discount-percent__position,
        $flag-discount-percent__size,
        $flag-discount-percent__padding,
        $flag-discount-percent__font
    );
}

%u-flag-discount-percent-lg {
    z-index: z-index(flag);
    @extend %u-flag-theme-discount-percent;
    @include u-flag(
        $flag-discount-percent-lg__type,
        $flag-discount-percent-lg__position,
        $flag-discount-percent-lg__size,
        $flag-discount-percent-lg__padding,
        $flag-discount-percent-lg__font
    );
}

%u-flag-bonus-circle{
    z-index: z-index(flag);
    display: flex;
    flex-direction: column;
    @extend %u-flag-theme-bonus;
    @include u-flag(
        $flag-bonus__type,
        $flag-bonus__position,
        $flag-bonus__size,
        $flag-bonus__padding,
        $flag-bonus__font
    );
    @extend %bonus-system-before-flag;
    &::before{
        margin-right: 0!important;
        margin-bottom: 0.1em;
    }
    text-transform: lowercase;
    line-height: 1.4em;
    font-size: 0.7em;
}