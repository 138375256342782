$enable-uc195-horizontal3-new-style: false !default; 

$uc195-horizontal3: () !default;
$uc195-horizontal3: map-merge(
    (
        "main-expand-button-height":            40px,
        "main-expand-button-color":             $cl_White,
        "show-disabled-criterias":              true,
        "selected-criterias-gap":               10px,
        "dropdown-btn-height":                  36px,
        "dropdown-btn-font-size":               14px,
        "dropdown-btn-font-size_xs":            15px,
        "dropdown-btn-font-weight":             500,
        "dropdown-btn-font-weight_xs":          500,
        "dropdown-btn-color":                   $u-btn-default-color,
        "dropdown-btn-bg-color":                $u-btn-default-bg,
        "dropdown-btn-border-color":            $u-btn-default-border,
        "dropdown-btn-color-hover":             $u-btn-default-color-hover,
        "dropdown-btn-bg-color-hover":          $u-btn-default-bg-hover,
        "dropdown-btn-border-color-hover":      $u-btn-default-border-hover,
        "dropdown-btn-disabled-text-color":     #595959,
        "dropdown-btn-disabled-border-color":   #C1C1C1,
        "gap-between-filters":                  10px 16px,
        "dropdown-item-v-padding":              8px,
        "dropdown-item-h-padding":              12px,
        "slider-range-height":                  2px,
        "slider-handle-size":                   14px,
        "slider-handle-border-radius":          50%,
        "slider-handle-background":             $brand-primary, // background-color, border-color
        "slider-filter-label-space":            11px,
        "slider-filter-vertical-align":         18px,
        "slider-filter-summary-line-height":    1em,
        "slider-filter-summary-font-size":      13px,
        "slider-filter-summary-margin-bottom":  8px,
        "selected-criteria-height":             28px,
        "selected-criteria-padding":            0 10px,
        "selected-criteria-text-color":         #595959,
        "selected-criteria-bg-color":           transparent,
        "selected-criteria-border-color":       #C1C1C1,
    ),
    $uc195-horizontal3
);

.UC195 {
    $self: ".uc195-horizontal3";

    margin-bottom: 4rem;

    .TopBox {        
        &::after {
            width: auto; // make more compact arrow
            color: map-get($uc195-horizontal3, "main-expand-button-color");
        }
    }
    .MainTitle {
        margin-bottom: 0;
        font-size: 16px;
        line-height: map-get($uc195-horizontal3, "main-expand-button-height");
        color: map-get($uc195-horizontal3, "main-expand-button-color");
        @media (max-width: $screen-xs-max) {
            text-transform: uppercase;
        }
        @media (min-width: $screen-sm-min) {
            font-size: $font-size-h1;
            line-height: $headings-line-height;
            color: $headings-color;
        }
    }

    .ui {
        &-slider {
            // 1px problem, ancestor container has overflow
            margin-left: floor(map-get($uc195-horizontal3,"slider-handle-size") * 0.5) + 1px;
            margin-right: floor(map-get($uc195-horizontal3,"slider-handle-size") * 0.5) + 1px;
            & {
                border: 0;
                height: map-get($uc195-horizontal3,"slider-range-height");
                background: none;
                &::after {
                    background-color: $cl_Alto1;
                }
            }
            &::after,
            &-range,
            &-handle {
                // vertical aligning at the center of .ui-slider
                position: absolute;
                top: 50% !important; //importance set because jqueri-ui.min.css overrides this rule
                transform: translate3d(0, -50%, 0);
            }
            &::after,
            &-range {
                left: 0;
                right: 0;
                content: "";
                height: map-get($uc195-horizontal3,"slider-range-height") !important;
            }
            &-range {
                background-color: $brand-primary;
            }
            &-handle {
                width: map-get($uc195-horizontal3,"slider-handle-size") !important;
                height: map-get($uc195-horizontal3,"slider-handle-size") !important;
                border: 0 !important;
                border-radius: map-get($uc195-horizontal3,"slider-handle-border-radius");
                background: map-get($uc195-horizontal3,"slider-handle-background") !important;
            }
        }


    }
    // buttons hides in ProductFilter.js (for backward compatibility)
    // hide it in css too for preventing 'flash' effect
    .FilterButtons {
        display: none !important;
    }
}

.uc195-horizontal3 {

    $self: &;

    &__panel-body {
        @media (min-width: $screen-sm-min) {
            
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: map-get($uc195-horizontal3, "slider-filter-vertical-align");
        }
    }
    hr{
        border-top: 1px solid $dropdown-border;

        @if($enable-uc195-horizontal3-new-style == false) {
            margin-bottom: 10px;
            margin-top: 10px;
        } @else {
            margin-top: map-get($uc195-horizontal3, "hr-vertical-align-top");
            margin-bottom: map-get($uc195-horizontal3, "hr-vertical-align-bottom");
        } 
    }
    .sections-group {
        &_dropdown{
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            @media (min-width: $screen-sm-min) {
                flex-direction: row;
                gap: map-get($uc195-horizontal3,"gap-between-filters");                
            }
        }
        &_slider{
            @media (min-width: $screen-sm-min) {
                margin-left: auto;
            }
        }
    }

    &__panel-footer {
        display: flex;
        flex-direction: column;
        @media (min-width: $screen-sm-min) {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
        }
        .selected-criterias {
            display: none;
            @media (min-width: $screen-sm-min) {
                display: flex;
                flex-wrap: wrap;
                gap: map-get($uc195-horizontal3,"selected-criterias-gap");
            }
            .criteria {
                display: inline-flex;
                align-items: center;
                min-height: map-get($uc195-horizontal3,"selected-criteria-height"); // for long labels
                padding: map-get($uc195-horizontal3,"selected-criteria-padding");
                text-align: left;
                font-weight: 400;
                white-space: normal; // for long labels
                padding-left: 16px;
                padding-right: 16px;

                @extend %u-btn;
                @include button-variant(
                    map-get($uc195-horizontal3, "selected-criteria-text-color"),
                    map-get($uc195-horizontal3, "selected-criteria-bg-color"),
                    map-get($uc195-horizontal3, "selected-criteria-border-color")
                );

                .applied-criteria{
                    &::before{
                        display: inline-block;
                        content: ">";
                        margin-left: 5px;
                        margin-right: 5px;
                    }
                }
                .icon {
                    margin-left: 7px;
                    font-size: 0;
                    &::before {
                        font-size: 11px;
                        @extend %fa-close;
                    }
                }
            }
        }
        .action-buttons {
            display: flex;
            justify-content: space-between;
            gap: 16px;
            button{
                @extend %u-btn;
            }
        }
    }

    section.filter {
        display: block;

        &.is-opened {
            div.dropdown {
                display: block;
            }
            button.dropdown .icon::before {
                transform: rotate(90deg);
                @media (min-width: $screen-sm-min) {
                    transform: rotate(180deg);
                }
            }
        }
        &.is-closed {
            div.dropdown {
                display: none;
            }
            button.dropdown .icon::before {
                transform: rotate(0);
            }
        }

        ul{
            padding: 0;
            margin-bottom: 0;
            list-style: none;
            min-width: 120px;
            overflow-y: auto;
            max-height: 40vh;
            @media (min-width: $screen-sm-min) {
                @include scrollbar();
            }
        }

        li{
            cursor: pointer;
            position: relative;

            &:not(:first-child)::after{
                content: "";
                display: block;
                position: absolute;
                height: 1px;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% - #{map-get($uc195-horizontal3, "dropdown-item-h-padding")} * 2);
                background-color: $dropdown-border;
            }

            @media (min-width: $screen-sm-min) {
                &:hover{
                    @if($enable-uc195-horizontal3-new-style == false) {
                        background-color: $dropdown-border; 
                    } @else {
                        background-color: map-get($uc195-horizontal3, "dropdown-btn-bg-color-hover"); 
                    }
                                
                    &::after{
                        display: none;
                    }
                    + li{
                        &::after{
                            width: 100%;
                        }
                    }
                }
            }

            .icon{
                min-width: 12px;
                display: inline-block;
            }

            input[type="checkbox"]{
                display: none;
                &[disabled] + label {
                    cursor: $cursor-disabled;
                    color: $btn-link-disabled-color;
                    .icon::before{
                        @extend %fa-close;
                        font-size: 10px;
                    }
                }
                &:checked + label {
                    .icon::before{
                        @extend %fa-check;
                        font-size: 12px;
                    }
                }
            }

            label{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-bottom: 0;
                font-weight: 400;
                gap: 8px;
                padding: map-get($uc195-horizontal3, "dropdown-item-v-padding") map-get($uc195-horizontal3, "dropdown-item-h-padding");
                
                @if($enable-uc195-horizontal3-new-style == true) {
                    font-size: map-get($uc195-horizontal3, "dropdown-btn-font-size");
                } 

                @media (min-width: $screen-sm-min) {
                    white-space: nowrap;
                }
            }

            .name{
                flex-grow: 1;
            }

            .count {
                display: none;
                font-size: 10px;
                &::before {
                    content: "(";
                }
                &::after {
                    content: ")";
                }
            }

            &.is-mobile-hidden{
                display: none;
                @media (min-width: $screen-sm-min) {
                    display: block;
                }
            }

            @if map-get($uc195-horizontal3,"show-disabled-criterias") == false {
                &.is-disabled {
                    display: none;
                }
            }
        }

        // Embded by ProductFilter.js
        .selected-criterias{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            text-align: left;
            margin-top: 6px;

            @media (min-width: $screen-sm-min) {
                display: none;
            }

            .criteria{
                color: $gray-light;
                &:not(:last-child){
                    &::after{
                        content: ", ";
                    }
                }
            }
        }

        .action-buttons {
            padding: 8px;
            display: flex;
            justify-content: space-between;
            gap: 16px;
            border-top: 1px solid $dropdown-border;
        }

        div.dropdown {
            list-style: none;
            margin-bottom: 0;
            text-align: left;
            border: 1px solid $dropdown-border;
            margin-bottom: 12px;

            @media (min-width: $screen-sm-min) {
                position: absolute;
                top: 100%;
                left: 0;
                z-index: z-index(dropdown);
                margin-top: -1px;
                border-radius: $border-radius-base;
                background-color: $dropdown-bg;
                box-shadow: 0 3px 6px rgba($cl_Black, .16);
            }
        }

        &_dropdown{
            position: relative;
            border-bottom: 1px solid $cl_Mercury;
            @media (min-width: $screen-sm-min) {
                border-bottom: none;
            }
            &.is-disabled {
                button.dropdown{
                    color: map-get($uc195-horizontal3, "dropdown-btn-disabled-text-color");
                    @media (min-width: $screen-sm-min) {
                        @include button-variant(
                            map-get($uc195-horizontal3, "dropdown-btn-disabled-text-color"),
                            $cl_White,
                            map-get($uc195-horizontal3, "dropdown-btn-disabled-border-color")
                        );
                    }
                }
            }
            @if map-get($uc195-horizontal3,"show-disabled-criterias") == false {
                &.is-disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                    pointer-events: none;
                    div.dropdown{
                        display: none;
                    }
                }
            }
        }

        &_slider{
            margin-top: 12px;

            @media (min-width: $screen-sm-min) {
                margin-top: 0;
                display: flex;
                align-items: center;
            }
            .name{
                // exists only for slider filters
                @media (min-width: $screen-sm-min) {
                    margin-right: map-get($uc195-horizontal3,"slider-filter-label-space");
                }
            }
            .slider {
                margin-bottom: 15px;
                @media (min-width: $screen-sm-min) {
                    margin-bottom: 0px;
                    width: 200px;
                    vertical-align: map-get($uc195-horizontal3, "slider-filter-vertical-align");
                    margin-top: -1 * map-get($uc195-horizontal3, "slider-filter-vertical-align");
                }
            }
            .summary {
                line-height: map-get($uc195-horizontal3,"slider-filter-summary-line-height");
                font-size: map-get($uc195-horizontal3,"slider-filter-summary-font-size");
                margin-bottom: map-get($uc195-horizontal3,"slider-filter-summary-margin-bottom");
                white-space: nowrap;
                text-align: center;

                &__count {
                    &::before {
                        content: "(";
                    }
                    &::after {
                        content: ")";
                    }
                }
            }
        }
    }

    button{
        &.section-show-more-criterias{
            @media (min-width: $screen-sm-min) {
                display: none !important;
            }
        }
        &.section-apply-criterias{
            margin-left: auto;

            @if($enable-uc195-horizontal3-new-style == true) {
                height: map-get($uc195-horizontal3, "dropdown-btn-height");
                font-size: map-get($uc195-horizontal3, "dropdown-btn-font-size");
            }
        }
        &.dropdown {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            color: $text-color;
            line-height: 1em;
            
            @extend %u-btn;
            @media (min-width: $screen-sm-min) {
                padding-left: 16px;
                padding-right: 16px;
                height: map-get($uc195-horizontal3, "dropdown-btn-height");                
                @include uni-button-advanced-hover(
                    map-get($uc195-horizontal3, "dropdown-btn-color"),
                    map-get($uc195-horizontal3, "dropdown-btn-bg-color"),
                    map-get($uc195-horizontal3, "dropdown-btn-border-color"),
                    map-get($uc195-horizontal3, "dropdown-btn-color-hover"),
                    map-get($uc195-horizontal3, "dropdown-btn-bg-color-hover"),
                    map-get($uc195-horizontal3, "dropdown-btn-border-color-hover")
                );
            }
            @media (max-width: $screen-xs-max) {
                background: none;
                padding: 12px 0;
                border: 0;
                &:active,
                &:focus {
                    box-shadow: none;
                }
            }            
            &:focus {
                outline: none;
                &:active {
                    outline: none;
                }
            }

            .icon {
                margin-left: 8px;
                margin-right: 10px;
                @media (min-width: $screen-sm-min) {
                    margin-right: 0;
                }
                &::before {
                    @extend %fa;
                    content: "\f067"; //fa fa-plus
                    
                    
                    transition: transform $animation-duration;
                    transform-origin: 50% 50%;

                    @if($enable-uc195-horizontal3-new-style == false) {
                        display: inline-block;
                        font-size: 10px;
                    } @else {
                        color: $text-color;
                        display: block;
                        font-size: 16px;
                    }

                    @media (min-width: $screen-sm-min) {
                        @if($enable-uc195-horizontal3-new-style == false) {
                            content: "\f078"; //fa-chevron-down
                        } @else {
                            content: "\f0d7";
                        }
                    }
                }
            }
        }

        &.clear {
            padding: 0;
            text-decoration: underline;
            background-color: transparent;
            color: $link-color;

            &::focus,
            &::active{
                box-shadow: none;
            }
        }
    }

    button.dropdown .name,
    .filter_slider .name {
        @if($enable-uc195-horizontal3-new-style == false) {
            text-transform: uppercase;
        }

        font-size: map-get($uc195-horizontal3,"dropdown-btn-font-size_xs");
        font-weight: map-get($uc195-horizontal3,"dropdown-btn-font-weight_xs");        
        @media (min-width: $screen-sm-min) {
            font-size: map-get($uc195-horizontal3,"dropdown-btn-font-size");
            font-weight: map-get($uc195-horizontal3,"dropdown-btn-font-weight");
        }
    }
}