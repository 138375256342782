@mixin u-sticker {
    // don't forget to provide z-index in the code
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin u-sticker-rectangle ($height, $padding) {
    height: $height;
    padding: $padding;
}

@mixin u-sticker-square ($size) {
    width: $size;
    height: $size;
    padding: 0;
}

@mixin u-sticker-circle ($size) {
    border-radius: 50%;
    @include u-sticker-square($size);
}

@mixin u-sticker-position ($position: 0 0 0 0, $includeAutoValues: true) {
    @if nth($position, 1) != auto or $includeAutoValues {
        top: nth($position, 1);
    }
    @if nth($position, 2) != auto or $includeAutoValues {
        right: nth($position, 2);
    }
    @if nth($position, 3) != auto or $includeAutoValues {
        bottom: nth($position, 3);
    }
    @if nth($position, 4) != auto or $includeAutoValues {
        left: nth($position, 4);
    }
}