//Common element for UC110 Detailed Cart and UC140 Checkout 2 controls
.CartGrid {
    border: solid 1px $cl_Cararra;
    background-color: $cl_White;
    &Header {
        height: 30px;
        line-height: 30px;
        font-weight: $label-font-weight;
        white-space: nowrap;
        td {
            padding: 6px 10px;
        }
    }
    tbody {
        tr:not(.CartGridHeader){
            > td{
                padding: 3px 10px;
                vertical-align: middle;
                border-top: 1px solid $cl_Cararra;
            }
            &:hover{
                > td{
                    background-color: lighten($gray-base, 96%);
                }
            }
        }
        tr{
            &.ChildLine > td {
                padding: 1px 10px;
                border-top: none !important;
            }
            //&.LimitedDeliveryProduct { //TODO: remove comment when SUP-2407 is resolved
            //    background-color: $state-warning-bg;
            //}
            &.Undeliverable {
                background-color: $state-danger-bg;
            }
        }
    }
}