.UC129 {

    &ErrorContainer {
        overflow: hidden;
        font-size: 12px;
    }

    &ErrMsg[style*="inline"] { /*hack functionality:)*/
        display: block !important;
        margin-bottom: 10px;
    }

    &ImageCodeCont >  {
        * {
            display: inline-block;
            vertical-align: middle;
        }
        .FormBox {
            width: 115px;
            height: 32px;
        }
    }

    @media (max-width: $screen-xs-max) {
        &FormBox,
        &FormMultiBox {
            font-size: 12px;
        }
    }

    @media(max-width: $screen-sm-max){
        &MainContainer .ReCaptcha2{
            transform: scale(0.77);
        }
    }

}