.site-header-mobile {

    .profile-quick-menu {
        &,
        &__btn {
            display: flex;
            align-self: stretch;
        }
        &__un-user {
            @extend %site-header-mobile-btn;
            &::before {
                font-size: inherit;
            }
        }
        &__dropdown {
            top: 100%;
        }
    }

}