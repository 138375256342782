$site-why-buy-fs:                9px !default;
$site-why-buy-fs_sm:             10px !default;
$site-why-buy-fs_md:             12px !default;
$site-why-buy-space:             8px !default;
$site-why-buy-space_md:          27px !default;
$site-why-buy-check-space:       5px !default;
$site-why-buy-check-color:       $brand-primary !default;
$site-why-buy-text-color:        $cl_White !default;

.site-why-buy {
    ul {
        @extend %flex;
        margin: 0;
        padding: 0;
    }

    li {
        @extend %flex;
        @extend %flex-item-center;
        position: relative;
        color: $site-why-buy-text-color;
        list-style: none;
        margin-left: $site-why-buy-space_md;
        text-transform: uppercase;

        &:first-child {
            margin-left: 0;
        }

        &::before {
            @extend %un-check;
            margin-right: $site-why-buy-check-space;
            color: $site-why-buy-check-color;
        }

        &:hover,
        &:focus {
            blockquote {
                visibility: visible;
                opacity: 1;
            }
        }

        @media (min-width: $screen-sm-min) {
            font-size: $site-why-buy-fs_sm;
        }

        @media (min-width: $screen-md-min) {
            font-size: $site-why-buy-fs_md;
        }
    }

    blockquote {
        position: absolute;
        top: calc(100% + 15px);
        border-left: 0;
        left: 0;
        z-index: 1;
        display: block;
        opacity: 0;
        visibility: hidden;
        background-color: $cl_White;
        color: $text-color;
        min-width: 215px;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.15);
        transition: visibility .3s ease, opacity .3s ease;

        &::before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 15px 15px 15px;
            border-color: transparent transparent $cl_White transparent;
            position: absolute;
            top: -14px;
            left: 0;


            @media (min-width: $screen-lg-min) {
                left: 50%;
                transform: translate(-50%, 0);
            }
        }

        @media (min-width: $screen-sm-min) {
            font-size: $site-why-buy-fs_sm;
        }

        @media (min-width: $screen-md-min) {
            font-size: $site-why-buy-fs_md;
        }

        @media (min-width: $screen-lg-min) {
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
}
