.Item {
    display: inline-block;
    vertical-align: top;
}

/*size: Mobile*/
@media (max-width: $screen-xs-max) {
    @for $i from 1 through length($items-xs-gutters) {
        .item-xs-#{$i} .Item {
            @include row-machine($i, nth($items-xs-gutters, $i));
        }
    }
    .item-xs-hide5 .Item:nth-child(4) ~ div {
        display: none;
    }
}

/*size: TabletPortrait*/
@media (min-width: $screen-sm-min) {
    @for $i from 1 through length($items-sm-gutters) {
        .item-sm-#{$i} .Item {
            @include row-machine($i, nth($items-sm-gutters, $i));
        }
    }
    .item-sm-hide5 .Item:nth-child(4) ~ div {
        display: none;
    }
}

/*size: TabletLandscape*/
@media (min-width: $screen-md-min) {
    @for $i from 1 through length($items-md-gutters) {
        .item-md-#{$i} .Item {
            @include row-machine($i, nth($items-md-gutters, $i));
        }
    }
}

/*size: Desktop and mobile*/
@media (min-width: $screen-lg-min) {
    @for $i from 1 through length($items-lg-gutters) {
        .item-lg-#{$i} .Item {
            @include row-machine($i, nth($items-lg-gutters, $i));
        }
    }
}

@media only screen and (max-width: $screen-xs-max) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    @for $i from 1 through length($items-xs-gutters) {
        .item-landscape-xs-#{$i} .Item {
            @include row-machine($i, nth($items-xs-gutters, $i));
        }
    }

    .item-xs-hide5 .Item:nth-child(4) ~ div {
        display: none;
    }
}

@media only screen and (min-device-width: $screen-sm-min) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    @for $i from 1 through length($items-sm-gutters) {
        .item-landscape-sm-#{$i} .Item {
            @include row-machine($i, nth($items-sm-gutters, $i));
        }
    }
    .item-sm-hide5 .Item:nth-child(4) ~ div {
        display: none;
    }
}

@media only screen and (min-device-width: $screen-md-min) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    @for $i from 1 through length($items-md-gutters) {
        .item-landscape-md-#{$i} .Item {
            @include row-machine($i, nth($items-md-gutters, $i));
        }
    }
}