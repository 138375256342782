/*UC29 Update Password*/
.uc29 {

    $uc29__gap: .75rem;

    @media (max-width: $screen-xs-max) {
        width: 100%;
    }

    &-send-password {
        &__info-text {
            // dont need bottom margin here because of <br />
        }
    }

    &-save-password {
        &__info-text {
            margin-bottom: 2rem;
        }
        &__password-1,
        &__password-2 {
            margin-top: $uc29__gap;
            margin-bottom: $uc29__gap;
            @media (max-width: $screen-xs-max) {
                width: 100% !important;
            }
        }
        &__btn-save {
            margin-top: $uc29__gap;
            width: 100%;
            text-transform: uppercase;
            @extend %u-btn;
            @extend %u-btn-primary;
        }
    }

    td {
        vertical-align: middle;
    }
    td + td {
        padding-left: 1rem;
    }
    &__redirect-to-customers-details-link{
        font-weight: $label-font-weight;
        text-decoration: underline;
    }
}
/*_UC29 Update Password*/