/*Last checkout step - "Thank you" message */
.UC147 {
    max-width: 668px;
    .ReceiveNewsletterContainer{
        @extend %card-default-style;
        @extend %card-padding-base;
        margin-bottom: $grid-size * 4;
        > h3, h1{
            //h3 is listed for backward compatibility only, see UMWC-4161.
            //When all project get this update the h3 can be removed.
            margin-top: 0;
        }
    }
    .TitleContainer {
        margin-bottom: 0;
        flex-basis: 100%;
        * {
            font-size: $font-size-h2; // that must be here
            margin: 0 0 0 19px; /* disable extra space for h1 inside TitleContainer */
        }
    }
    .MsgThankYou {
        float: none;
        clear: both;
        line-height: 23px;
        margin-top: $grid-size * 4;
        margin-bottom: 25px;
        max-width: 689px;
        p {
            margin-top: 10px;
            &:first-child {
                margin-top: 0;
            }
        }
    }
    .FailContainer,
    .SuccessContainer {
        display: inline-flex;
        flex-wrap: wrap;
        padding: 10px;
        line-height: 22px;
        font-size: $font-size-h2;
        font-weight: $label-font-weight;
        align-items: baseline;
    }
    .SuccessContainer {
        background-color: $alert-success-bg;
        color: $alert-success-text;
        a {
            font-weight: $alert-link-font-weight;
            color: darken($alert-success-text, 10%); // like in bootstrap
        }
        &::before {
            margin-right: 6px;
            font-size: 13px;
            @extend %fa-check;
        }
    }
    .FailContainer {
        background-color: $alert-danger-bg;
        color: $alert-danger-text;
        a {
            font-weight: $alert-link-font-weight;
            color: darken($alert-danger-text, 10%); // like in bootstrap
        }
    }
    .BonusInfoContainer{
        @extend %bonus-system-before-flag;
    }
    .BonusInfoLink{
        font-weight: $label-font-weight;
        text-decoration: underline;
    }
}