$UC170__image-width:        90px !default;
$UC170__price-buy-width:    240px !default;
$UC170__space:              16px !default;

$UC170__image-width_sm:     120px !default;
$UC170__price-buy-width_sm: 240px !default;

$UC170__title-font-size:    1.6rem !default;
$UC170__teaser-font-size:   1.4rem !default;
$UC170__price-font-size:    1.5rem !default;

.UC170 .Teaser p {
    @extend %u-text-inherit;
}

.UC170 {
    .Stats {
        color: $cl_Tapa;
        margin-bottom: 30px;
        @media (min-width: $screen-sm-min) {
            float: left; // depends on .SortingContainer
        }
    }
    .SearchViewControls {
        margin-bottom: 30px;
        @media (min-width: $screen-sm-min) {
            float: right;
        }
    }
    .ShowOnlyInStock {
        margin-right: 10px;
        input {
            position: relative;
            top: 1px;
        }
        label {
            margin-left: 3px;
            font-weight: 400;
        }
    }
    .SortingContainer { // could be enabled at site settings
        display: inline-block;
    }
    .SearchListContainer {
        clear: both;
        @extend %card-default-style;
        padding-top: $grid-size;
        padding-left: 0;
    }
    .MakeOrderContainer { ///TODO: Remove MakeOrderContainer container from layout when possible, check, javascript could use it
        margin-top: $grid-size;
    }
    .DataListItemsRow {
        display: block;
        padding: $grid-size;
        @media (min-width: $screen-sm-min) {
            padding-right: $grid-size * 3;
        }
        &:not(:first-child) {
            border-top: 1px solid $cl_Gray_Nurse1;
        }
    }
    .WishListContainer {
        margin-top: 15px;
    }
    .ProductNumberCont {
        margin-bottom: 5px;
    }
    .Price {
        font-size: $UC170__price-font-size;
    }
    .PriceTxt {
        font-size: 10px;
        color: $cl_Tapa;
    }
    .OriginalPriceTxt {
        font-size: 10px;
    }
    .QuantityContainer{
        display: none; //WTG-315 Quantity is hidden on purpose, comments at WTG-285
    }
    //.QuantityDrp{
    //    @extend %u-quantity-drp-small;
    //}
    //.QuantityTxb{
    //    @extend %u-quantity-txb-small;
    //}
    .btn-buy.DimensionProduct{
        @extend %u-btn-default;
    }
}

.uc170 {
    &__item {
        display: flex;
        align-items: center;

        &-title-cont {
            margin-bottom: $grid-size * 2;
            font-size: $UC170__title-font-size;
            overflow: hidden;

            @media (min-width: $screen-sm-min) {
                margin-top: 0;
                margin-bottom: 0.6em;
            }
        }
        &-title{
            @extend %u-text-inherit;
            font-weight: $label-font-weight;
            line-height: 1.2em;
        }
        &-title-link{
            @extend %u-list-title-d-to-l;
        }

        &-details {
            @media (min-width: $screen-sm-min) {
                width: calc(100% - #{$UC170__price-buy-width});
            }
        }

        &-details-cont {
            @extend %flex;
            @extend %flex-column-sm;
            align-items: flex-start;
            margin-left: $UC170__space;
            width: 100%;

            @media (min-width: $screen-sm-min) {
                align-items: center;
                flex: 1;
            }
        }
    }
    &__key {
        margin-right: 5px;
    }
    &__key,
    &__value {
        display: inline-block;
    }

    &__img-cont {
        position: relative;
        flex: 0 0 $UC170__image-width;

        @media (min-width: $screen-sm-min) {
            flex: 0 0 $UC170__image-width_sm;
        }
    }

    &__price-buy {
        @extend %flex;
        @extend %flex-column;
        align-items: flex-start;

        @media (min-width: $screen-sm-min) {
            align-items: flex-end;
            width: $UC170__price-buy-width;
        }
    }

    &__teaser-cont {
        display: none;

        @media (min-width: $screen-sm-min) {
            display: block;
            max-height: 4.2em;
            overflow: hidden;
            line-height: 1.4;
            font-size: $UC170__teaser-font-size;
        }
    }
}

/*_Search Result page*/
