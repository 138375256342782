.profile-quick-menu {
    &__dropdown {
        margin: 0;
        padding: 1rem 0;
        position: absolute;
        top: calc(100% + 3px);
        right: 0;
        display: none;
        min-width: 16rem;
        z-index: 10;
        border-radius: $border-radius-base;
        background-color: $cl_White;
        box-shadow: 0 2px 5px rgba($cl_Black, 0.2);

        li {
            list-style: none;

            a {
                display: block;
                padding: .3rem 1.5rem;
                color: $text-color;
                &:hover {
                    text-decoration: underline;
                }
            }

            &:last-child {
                border-top: 1px solid $cl_Cararra;
                margin-top: .7rem;
                padding-top: .7rem;
            }
        }
    }
}