.umw-quick-buy-autocomplete-popup{
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;

    li{
        display: flex;
        align-items: center;
    }
    &__term-image{
        margin-right: 16px;
    }
    &__product-in-cart{
        background-color: $brand-primary;
    }
    .ui-state-focus, .ui-widget-content .ui-state-focus{
        border-color: transparent;
        background: rgba($brand-primary, 0.3);
    }
}