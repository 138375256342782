.uc-product-view__pane-container_markdown{
    table{
        width: 100%;
        border: 1px solid $table-border-color;
        border-top: none;
        table-layout: fixed;
        border-spacing: 0;
        border-collapse: collapse;
        @media (min-width: $screen-sm-min) {
            max-width: 560px;
        }

        &:not(:first-child){
            margin-top: 20px;
        }
        thead{
            background-color: $table-border-color;
        }
        th,td{
            padding: 10px;
        }
        td{
            border: 1px solid $table-border-color;
        }
    }
}