.ErrMsg,
.ErrorMsg, /* UC176 */
.error {
    color: $brand-danger;
}
.Star{
    color: $cl_Bright_Red; // Should be darken than $brand-danger color by WCAG
}
.RequiredField{
    color: $text-color;
}
.Msg,
.SuccessMsg /* UC176 */ {
    color: $brand-success;
}
.Invalid { //UC140 Checkout 2 inputs, maybe other controls
    background-color: $cl_Your_Pink !important;
}