$scroll-to-top__type:               square !default; // square, circle
$scroll-to-top__size:               3.5rem !default;
$scroll-to-top__font-size:          1.3rem !default;
$scroll-to-top__position:           auto 1rem 1rem auto !default;
$scroll-to-top__background-color:   rgba($gray-base, .1) rgba($gray-base, .35) !default; // general color, hover color
$scroll-to-top__color:              $cl_White $cl_White !default; // general color, hover color
$scroll-to-top__mobile-only:        true !default; // set to false to render it on all screen sizes

.scroll-to-top {
    position: fixed;
    z-index: z-index(site-scroll-to-top);
    border: 0;
    opacity: 1;
    background-color: nth($scroll-to-top__background-color, 1);
    font-size: $scroll-to-top__font-size;
    color: nth($scroll-to-top__color, 1);
    transition: opacity $animation-duration, background-color $animation-duration;
    @extend %u-sticker;
    @if $scroll-to-top__type == square {
        border-radius: $border-radius-base;
        @include u-sticker-square($scroll-to-top__size);
    } @else {
        @include u-sticker-circle($scroll-to-top__size);
    }
    @include u-sticker-position($scroll-to-top__position, false);

    &::before {
        @extend %fa-chevron-up;
    }

    &:hover,
    &:focus {
        background-color: nth($scroll-to-top__background-color, 2);
        color: nth($scroll-to-top__color, 2);
    }
    &:focus {
        outline: none;
    }

    &_disabled {
        opacity: 0;
        pointer-events: none;
    }

    @if $scroll-to-top__mobile-only {
        @media (min-width: $screen-md-min) {
            opacity: 0;
            pointer-events: none;
        }
    }

}