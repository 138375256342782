.uc193-default{
    $this: &;
    .c-rating{
        display: inline-flex;
    }
    .Item{
        //&.DimensionProduct
        //&:not(.DimensionProduct)

        border: none; //reset default.html.css
        padding: 0 0 20px; //reset default.html.css
        @extend %card-default-style;
        &:hover {
            .WishListBtn {
                visibility: visible;
                opacity: 1;
            }
            .Image{
                filter: brightness(90%);
            }
        }
    }
    .Image{
        transition: filter 1s;
    }
    .ItemTitle{
        line-height: 2.2rem;
        font-size: $font-size-base;
    }
    .ItemTitleLink{
        @extend %u-list-title-d-to-l;
    }

    .ItemTitleContainer {
        margin-top: 1.4rem; //reset default.html.css
        padding: 0 1em;
    }

    .Key {
        min-width: 0;
        margin-right: 0.4em;
    }
    .HasRelatedImage{
        &:hover{
            #{$this}__image{
                opacity: 0;
            }
        }
    }
    .ProductImageLink{ // main image container
        display: block;
        position: relative;
        z-index: 0;
    }
    &__image{
        position: relative;
        z-index: 1;
        opacity: 1;
        transition: opacity $animation-duration;
        &-alt{
            position: absolute;
            top:0;
            bottom: 0;
            z-index: 0;
        }
    }
    .Image{
        margin-left: auto;
        margin-right: auto;
    }
    .BrandTxt{
        display: none;
    }
    .Brand h3{
        margin-top: 0;
        font-size: $font-size-h6;
    }

    .WishListBtn{
        @extend %u-wish-list-btn-left-bottom;
        &.Add,
        &.NeedsAdditionalInputToBuy {
            @extend %u-wish-list-add-btn;
        }
        &.Remove{
            @extend %u-wish-list-remove-btn;
        }
        @media (min-width: $screen-sm-min) {
            opacity: 0;
            transition: opacity 0.2s;
        }
    }

    .DiscountPercentTxt{
        display:none;
    }
    .DiscountPercent{
        @extend %u-flag-discount-percent
    }
    .PricesContainer {
        margin-top: 1.8rem; //reset default.html.css
    }
    .PriceRow{
        display: inline-flex;
        align-items: baseline;
        flex-wrap: wrap;
    }
    .OriginalPrice{
        margin-right: 5px;
    }
    .PriceVatTxt{
        margin-left: 5px;
    }
    .Price {
        &:after {
            padding-left: 4px;
            font-size: 10px;
            font-weight: normal;
        }
    }
    .ButtonsContainer {
        margin-top: 1.1rem; //reset default.html.css
    }
    .ShoppingListBtn{
        @extend %u-btn-size-small;
    }
    .ButtonsContainer{
        display: flex;
        justify-content: center;
    }
    .QuantityDrp{
        @extend %u-quantity-drp-small;
        margin-right: $u-quantity-margin-right;
    }
    .QuantityTxb{
        @extend %u-quantity-txb-small;
        margin-right: $u-quantity-margin-right;
    }
    &-dims {
        display: none;
        @media (min-width: $screen-md-min) {
            display: block;
            position: absolute;
            bottom: -30px;
            left: 0;
            right: 0;
            z-index: -1;
            padding: 10px;
            box-shadow: 0 -4px 3px -2px rgba($cl_Black, .05);
            background-color: $cl_White;
            opacity: 0;
            transition: opacity $animation-duration, bottom $animation-duration;
        }
    }
    @media (min-width: $screen-md-min) {
        .Item:hover &-dims {
            bottom: 0;
            z-index: 2;
            opacity: 1;
        }
    }

    &-dim {
        &-item {
            display: inline-block;
            &__link {
                display: inline-block;
                padding: 0.2em 0.4em;
                color: $gray-light;
                &:hover {
                    text-decoration: none;
                    color: $gray-darker;
                }
            }
            &__img {
                display: block;
            }
            &__label {
                display: block;
                font-size: 10px;
            }
            // &_with-image {
            // }
            &_no-image {
                border: 1px solid $table-border-color;
                & + & {
                    margin-left: 4px;
                }
            }
        }
    }
    &-lipscore-rating-small{
        height: 20px;
    }
}