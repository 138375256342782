$uc-article: () !default;
$uc-article: map-merge(
    (
        "font-size-teaser": 1.6rem,
        "line-height-teaser": 2.4rem,
        "line-height-content": 2.2rem,
        "margin-formating": 0 0 2.5rem,
        "text-decoration-link-formating": underline,
    ),
    $uc-article
);


@mixin article-teaser{
    font-size: map-get($uc-article, "font-size-teaser");
    line-height: map-get($uc-article, "line-height-teaser");
}

@mixin article-content{
    line-height: map-get($uc-article, "line-height-content");
}

@mixin article-formating{
    > p,
    > ol,
    > ul {
        margin: map-get($uc-article, "margin-formating");
    }
    :last-child {
        margin-bottom: 0;
    }
    a{
        text-decoration: map-get($uc-article, "text-decoration-link-formating");
    }
    table{
        max-width: 100%;
    }
}

@mixin u-text-inherit{
    margin-top: 0;
    margin-bottom: 0;
    line-height: inherit;
    font-size: inherit;
    font-weight: inherit;
}

@mixin u-transition-colors{
    transition: border-color $animation-duration $animation-timing-function, background-color $animation-duration $animation-timing-function, color $animation-duration $animation-timing-function;
}

%u-transition-colors{
    @include u-transition-colors;
}


/// $font: <font-size> <font-weight> <font-style>;
@mixin font-properties ($font) {
    font-size: nth($font, 1);
    @if length($font) > 1 {
        font-weight: nth($font, 2);
    }
    @if length($font) > 2 {
        font-style: nth($font, 3);
    }
}